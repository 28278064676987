import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '../../components/container/Container'
import { Paragraph } from '../../components/text/Paragraph'
import { Title } from '../../components/text/Title'
import { useData } from '../../context/useData'

import { ReactComponent as PlusIcon } from "../../assets/svg-icons/plus.svg"
import { ReactComponent as EyeIcon } from "../../assets/svg-icons/eye.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg-icons/delete.svg";
import { ReactComponent as CoinIcon } from "../../assets/svg-icons/coin.svg";

import { useQuery } from 'react-query'
import { getHelps } from '../../api/gameApi'
import { Loading } from '../loading/Loading'
import { ErrorMessage } from '../../components/errors/ErrorMessage'

export const Store = () => {
    const [currentType, setCurrentType] = useState(null);
    const { t } = useTranslation();
    const { user, setUser, setHandlePopup } = useData();

    const { isLoading, isError, error, refetch, isFetching } = useQuery(["get-helps", currentType ], () => getHelps(user?._id, currentType), {
        enabled: false,
        onSuccess: (data) => {
            setUser(data)
            setCurrentType(null)
        },
        
    })

    useEffect(() => {
      if (currentType) refetch()
    }, [currentType, refetch])
    

    if (isLoading || isFetching) return <Loading />

  return (
    <Container page>
        <Container classnames={'h-full !m-0'}>
        <Title title={t("screens.store")} />
            <Paragraph paragraph={t('store.get-helps')} />
            

        {isError && (
          <ErrorMessage
            text={
              error?.response?.data?.message
                ? error?.response?.data?.message
                : t("errors.network")
            }
          />
        )}

            <div className='w-full flex items-center justify-between my-6'>
                <p className='flex -items-center w-[35%]'><CoinIcon /><span className='mx-2'>{t('game.coins')}</span> </p>
                <p className='mx-2 text-secondery-color'>{user?.game?.solo?.soloActions?.coins}</p>
                <button className='p-2 bg-light rounded-xl disabled:opacity-40' disabled={true}><PlusIcon /></button>
            </div>
            <div className='w-full flex items-center justify-between my-6 '>
                <p className='flex -items-center w-[35%]'><DeleteIcon /><span className='mx-2'>{t("guess-riddle.remove")}</span> </p>
                <p className='mx-2 text-secondery-color'>{user?.game?.solo?.soloActions?.removeChars}</p>
                <button onClick={() => setHandlePopup({
            showPopup: true,
            popupData: {
              title: t('store.remove-title'),
              para: t('store.get-remove'),
              onclick:  () => {
                setCurrentType('remove')
              },
              label: t('common.get'),
              onclose: () => setHandlePopup({ showPopup: false })
            },
          })} className='p-2 bg-light rounded-xl disabled:opacity-60'><PlusIcon /></button>
            </div>
            <div className='w-full flex items-center justify-between my-6 '>
                <p className='flex -items-center w-[35%]'><EyeIcon /><span className='mx-2'>{t("guess-riddle.reveal")}</span> </p>
                <p className='mx-2 text-secondery-color'>{user?.game?.solo?.soloActions?.revealChars}</p>
                <button onClick={() => setHandlePopup({
            showPopup: true,
            popupData: {
              title: t('store.reveal-title'),
              para: t('store.get-reveal'),
              onclick: () => {
                setCurrentType('reveal')
              },
              label: t('common.get'),
              onclose: () => setHandlePopup({ showPopup: false })
            },
          })} className='p-2 bg-light rounded-xl disabled:opacity-60'><PlusIcon /></button>
            </div>
        
        </Container>
    </Container>
  )
}
