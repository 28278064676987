import React from 'react'
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';


const baseClassNames = "flex w-[100%] items-center justify-center border-4 shadow-md overflow-hidden border-primary-color rounded-lg py-4 mb-2  bg-light/80 relative  bg-center bg-contain"

export const Level = ({ level, isFinnished, index }) => { 
  const { i18n } = useTranslation()
  return (
    <div className={classNames(baseClassNames)}>
       {isFinnished && <div className={`w-full h-full absolute bg-[#00000036] ${isFinnished ? 'z-20' : ''}`}></div>} 
        <h1 className='text-3xl relative z-10 tracking-[0.3em] !dir-ltr' >{level.emojis[i18n.language.slice(0,2).toLocaleLowerCase()]}</h1>
    </div>
  )
}
