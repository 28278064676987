import React, { useEffect, useState } from "react";
import { Container } from "../../components/container/Container";
import { Title } from "../../components/text/Title";
import { Paragraph } from "../../components/text/Paragraph";
import { useTranslation } from "react-i18next";
import { MainInput } from "../../components/input/MainInput";
import { useData } from "../../context/useData";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import { Loader } from "../../components/loader/Loader";
import { MainButton } from "../../components/button/MainButton";
import { useQuery } from "react-query";
import { sendFeedback } from "../../api/userApi";

const initialFeedbackForm = {
  name: "",
  email: "",
  message: "",
};

export const Feedback = () => {
  const [feedbackForm, setFeedbackForm] = useState(initialFeedbackForm);
  const [errorMessage, setError] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const { t } = useTranslation();
  const { user } = useData();

  useEffect(() => {
    if (user && user !== "pending") {
      setFeedbackForm((prev) => ({
        ...prev,
        name: user?.fullName,
        email: user?.email,
      }));
    }
  }, [user]);

  const { isLoading, isError, refetch, error } = useQuery(
    "feedback",
    () => sendFeedback(feedbackForm, user?._id),
    {
      enabled: false,
      onSuccess: (data) => {
        setMessageSent(true);
      },
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setError("");
    let isValid = true;

    // Validation logic
    if (!feedbackForm.email || !feedbackForm.name || !feedbackForm.message) {
      setError(t("errors.login.fields"));
      isValid = false;
    }

    if (isValid) {
      await refetch();
    }
  };

  return (
    <Container page>
      <Container>
        <Title title={t("feedback.title")} />
        <Paragraph paragraph={t("feedback.paragraph")} />

        <div className="mt-4">
          <MainInput
            label={t("common.full-name")}
            type={"text"}
            name={"name"}
            value={feedbackForm["name"]}
            id="name"
            placeholder={t("common.full-name")}
            onchange={handleChange}
            isError={false}
          />
          <MainInput
            label={t("common.email-address")}
            type={"text"}
            name={"email"}
            value={feedbackForm["email"]}
            id="email"
            placeholder={t("common.email-address")}
            onchange={handleChange}
            isError={false}
          />
          <MainInput
            label={t("common.message")}
            type={"textarea"}
            name={"message"}
            value={feedbackForm["message"]}
            id="message"
            placeholder={t("common.message")}
            onchange={handleChange}
            isError={false}
          />

          {errorMessage && <ErrorMessage text={errorMessage} />}
          {isError && (
            <ErrorMessage
              text={
                // error?.response?.data?.message
                //   ? error?.response?.data?.message
                //   : 
                  t("errors.somthing-went-wrong")
              }
            />
          )}

          {messageSent && (
            <h2 className="text-sm text-lime-700 w-full text-center my-2">
              {t("feedback.message-sent")}
            </h2>
          )}

          <div className="text-center">
            {isLoading ? (
              <Loader />
            ) : (
              <MainButton
                label={t("common.send")}
                onclick={handleSubmit}
                disabled={messageSent}
              />
            )}
          </div>
        </div>
      </Container>
    </Container>
  );
};
