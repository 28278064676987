import React from 'react'
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {  useParams } from 'react-router-dom'
import { confirmEmail } from '../../../api/userApi';
import { MainButton } from '../../../components/button/MainButton';
import { Container } from '../../../components/container/Container'
import { Paragraph } from '../../../components/text/Paragraph';
import { Title } from '../../../components/text/Title';
import { urls } from '../../../globalConsts';

export const ConfirmEmail = () => {
    const { key } = useParams();
    const { t } = useTranslation();
    const { data, isLoading, isError, refetch } = useQuery('email-confirm', () => confirmEmail(key), {
        // enabled: false
    })

  return (
    <Container page classnames={"h-full w-full flex flex-col items-center justify-center"}>
        {isLoading && <>
            <Container >
            <Title title={`${t('confirm-email.confirming')}...`}/>
            <Paragraph paragraph={t('confirm-email.wait')} />
        </Container>
        </>}
        {(isError && !data) && <>
            <Container >
            <Title title={t('errors.somthing-went-wrong')}/>
            <Paragraph paragraph={t('errors.try-later')} />
        </Container>
        <MainButton label={t('common.try-again')} onclick={refetch} />
        </>}
        {data && <>
        <Container >
            <Title title={t('confirm-email.all-done')}/>
            <Paragraph paragraph={t('confirm-email.start-playing')}  />
            <MainButton label={t('common.login')} link={urls.login} classnames={"mt-8"} />
        </Container>
        </>
        }
    </Container>
  )
}
