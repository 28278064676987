import React from 'react'
import { Container } from '../../components/container/Container'
import GuessRiddle from '../../components/game-components/guesser/guess-riddle/GuessRiddle'

export const Compare = () => {
  return (
    <Container page>
  <GuessRiddle currentRiddle={{
    "answer": "The Boss Baby",
    "emojis": "🏠🕰️👦",
    "category": "movies",
    "subCategory": "animation",
    // "shownCharsIndexes": [1]
  }}/>
    </Container>
  )
}
