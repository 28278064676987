/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LeaveButton } from "../../components/button/LeaveButton";
import { CurrentPlayer } from "../../components/game-components/current-player/CurrentPlayer";
import { Guesser } from "../../components/game-components/guesser/Guesser";
import { Scoreboard } from "../../components/game-components/scoreboard/Scoreboard";
import { useSocket } from "../../context/useSocket";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
// import dataMock from "../../dataMock.json";
import { Container } from "../../components/container/Container";
import { Paragraph } from "../../components/text/Paragraph";
import { Loading } from "../loading/Loading";
import { UsersBar } from "../../components/game-components/users-bar/UsersBar";
import { timeBetweenRoundsInSeconds } from "../../globalConsts";
import { useInterval } from "../../hooks/useInterval";
import { MainButton } from "../../components/button/MainButton";
import { useTranslation } from "react-i18next";

export const GroupGame = () => {
  const [isFinnishedRound, setIsFinnishedRound] = useState(false);
  const [isFinnishedGame, setIsFinnishedGame] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null)
  const { roomId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    error: socketError,
    setError: setSocketError,
    socketUser,
    socketUsers,
    setSocketUsers,
    handleLeaveRoom,
    subscribeToUsersUpdate,
    subscribeToRoomUpdate,
    subscribeToGameUpdate,
    subscribeToChatUpdate,
    setCurrentRiddle,
    handleNextPlayer,
    setSocketUser,
  } = useSocket();

  const { isCopied, copyToClipboard } = useCopyToClipboard();

   // Timer
   const { timer, setIsRunning, restartTimer } = useInterval(timeBetweenRoundsInSeconds);


  // initilize game and socket listening
  useEffect(() => {
    if (!socketUser) navigate("/enter-user");

    // Subscription to socket events

    // subscribe to users updates
    subscribeToUsersUpdate((err, roomUsers, isGameOver) => {
        if (err) setSocketError(err)

         // update current user
      const currentUser = roomUsers.filter(
        (user) => user._id === socketUser._id
      )[0];
      if (currentUser) {
        setSocketUser({ ...currentUser });
        setSocketUsers([...roomUsers]);

        // check if finnished round
        let allFinnished = true;
        roomUsers.forEach((user, index) => {
          if (!user.isFinnishedRound) allFinnished = false;
        });
        if (allFinnished) {
          setIsFinnishedRound(true);        
        }

        
      // handle game over
      if (isGameOver) setIsFinnishedGame(true);
      }

    })

    // subscribe to room updates
    subscribeToRoomUpdate()

    // subscribe to game updates
    subscribeToGameUpdate()

    // subscribe to chat updates
    subscribeToChatUpdate()


    return () => {
      // leave room on unmount
      handleLeaveRoom();
    };
  }, []);

  // handle new round
  useEffect(() => {
    let timer;
    if (isFinnishedRound) {
      restartTimer()
      setIsRunning(true)
      timer = setTimeout(() => {
        setIsFinnishedRound(false);
        resetRound();
      }, timeBetweenRoundsInSeconds * 1000);
    }
    return () => clearTimeout(timer);
  }, [isFinnishedRound]);


  // handle socket errors
  useEffect(() => {
    if (socketError) {
      console.log(socketError)
      if (socketError.isCreator) {
        navigate('/lobby')
        setPopupMessage(socketError.message)
      } else {
        setPopupMessage(socketError.message)
      }
    }
  }, [socketError])


// message popup (example: user leave room)
  useEffect(() => {
    let timer;
    if (popupMessage) {
      timer = setTimeout(() => {
        setPopupMessage(null);
        setSocketError(null)
      }, 6000);
    }
    return () => clearTimeout(timer);
  }, [popupMessage])
  
  
  
// clear states and prepare new round
  const resetRound = () => {
    setCurrentRiddle({});
    setSocketUsers([]);
    handleNextPlayer();
  };


  
  if (socketUsers.length < 1) return <Loading />

  return (
    <Container page noHeader>
      <div className="flex items-center justify-between">
        <LeaveButton toUrl={'/lobby'} />
        <div className="flex">
        {isCopied && <p className="mx-4">{t('common.copied')}!</p>}
        <button className="bg-light px-2 rounded-lg" onClick={() => copyToClipboard(roomId)}>{t('gameRoom.room-id')} {roomId}</button>
        </div>
      </div>

      <UsersBar />

      
      <div className={`bg-light/70 rounded-xl my-2 transition-all ease-out ${popupMessage ? 'max-h-[200px] px-2 py-1' : 'max-h-0'}`}><Paragraph paragraph={popupMessage} classnames={'!text-center !m-0'}/></div>

      {isFinnishedGame ? (
        <>
          <Scoreboard users={socketUsers} isFinnishedGame={isFinnishedGame} />
          {socketUser.isCreator && <MainButton label={'Play Again'} onClick={() => {}}/>}
        </>
      ) : (
        <>
          {isFinnishedRound ? (
            <>
              <Scoreboard users={socketUsers} timer={timer} isFinnishedGame={isFinnishedGame} />
            </>
          ) : (
            <>{socketUser?.isPlaying ? <CurrentPlayer /> : <Guesser />}</>
          )}
        </>
      )}
    </Container>
  );
};

