import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MainButton } from "../../components/button/MainButton";
import { Container } from "../../components/container/Container";
import { SelectInput } from "../../components/select-input/SelectInput";
import { Title } from "../../components/text/Title";
import { langChars, languageOptions, urls } from "../../globalConsts";
import { LanguageSelect } from "../../components/settings/LanguageSelect";
import { FontSize } from "../../components/settings/FontSize";
import { Sounds } from "../../components/settings/Sounds";

export const Settings = () => {
  const { t } = useTranslation();

  return (
    <Container page>
      <Container>
        <Title title={t("screens.settings")} />
        <MainButton label={t("screens.profile")} link={urls.profile} />

        <SettingsOptionContainer label={t("settings.language")}>
          <LanguageSelect />
        </SettingsOptionContainer>

        <SettingsOptionContainer label={t("settings.font-size")}>
          <FontSize />
        </SettingsOptionContainer>

        <SettingsOptionContainer label={t("settings.audio")}>
          <Sounds />
        </SettingsOptionContainer>
        
      </Container>
    </Container>
  );
};

const SettingsOptionContainer = ({ children, label }) => {
  return (
    <div className="w-full mt-4">
      <label className="">{label}:</label>
      {children}
    </div>
  );
};
