/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";


export const SoundContext = React.createContext({});

export const SoundProvider = ({ children }) => {
    const [isMusic, setIsMusic] = useState(true);
    const [isSfx, setIsSfx] = useState(true);
    const [volume, setVolume] = useState(0.5);

    useEffect(() => {
      
    // Saved sound settings
    const savedIsMusic = localStorage.getItem('isMusic')
    if (savedIsMusic) setIsMusic(savedIsMusic === 'true' ? true : false);

    const savedIsSfx = localStorage.getItem('isSfx')
    if (savedIsSfx) setIsSfx(savedIsSfx === 'true' ? true : false);

    const savedVolume = localStorage.getItem('volume')
    if (savedVolume) setVolume(+savedVolume)

    }, [])

    const setMusic = () => {
        localStorage.setItem('isMusic', !isMusic)
        setIsMusic(!isMusic)
    }

    const setSfx = () => {
        localStorage.setItem('isSfx', !isSfx)
        setIsSfx(!isSfx)
    }
    const setVolumeValue = (value) => {
        localStorage.setItem('volume', value)
        setVolume(value)
    }
    

    const contextValue = {
        isMusic,
        setMusic,
        isSfx,
        setSfx,
        volume,
        setVolumeValue
    };

  return (
    <SoundContext.Provider value={contextValue}>{children}</SoundContext.Provider>
  );
};

export const useAppSound = () => useContext(SoundContext);
