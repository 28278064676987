import React, { useState } from "react";
import { Checkbox } from "../input/Checkbox";
import { useAppSound } from "../../context/useSound";
import { Slider } from "../input/Slider";

export const Sounds = () => {
  const { isMusic, setMusic, isSfx, setSfx, volume, setVolumeValue } =
    useAppSound();

  return (
    <div className="mt-2">
      <Checkbox value={isMusic} setValue={setMusic} text={"settings.music"} />
      <Checkbox value={isSfx} setValue={setSfx} text={"settings.sfx"} />
      {/* <Checkbox value={volume} setValue={setVolume} text={"settings.volume"} /> */}
      <Slider value={volume} setValue={setVolumeValue} name={"volume"} label={"settings.volume"} min={0} max={1} step={0.1} />
    </div>
  );
};
