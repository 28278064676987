import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useData } from "../../../context/useData";
import { Level } from "./Level";
import buttonSound from "../../../assets/sounds/main-click.mp3";
import { useAppSound } from "../../../context/useSound";
import useSound from "use-sound";


export const Levels = ({ levels, type, level}) => {
  const { user } = useData();
  const navigate = useNavigate();
  // const { t } = useTranslation();

  const { volume, isSfx } = useAppSound();

  const [play, { sound }] = useSound(buttonSound, {
    playbackRate: 0.75,
    interrupt: true,
  });

  useEffect(() => {
    if (sound) {
      sound.volume(isSfx ? volume : 0);
    }
  }, [volume, isSfx, sound]);

  const handleOpenLevel = (index) => {
     if (isSfx) play()
    navigate(`/solo/${type}/${level}/${index}`);
  };

  return (
    <div className=" overflow-auto flex-1">
      {levels?.map((level, index) => (
        <div key={index}>
        
        <div
          key={index}
          onClick={user?.game?.solo?.levelsCompletedIds?.includes(level._id) ? () => {} : () => handleOpenLevel(index)}
          className="flex flex-col items-center justify-center "
          
        >
          {/* {index % 20 === 0 && (
            <h4 className="mb-2 capitalize text-lg">
              {t(`sub-categories.${level.subCategory}`)}
              
              <span className="mx-2 text-[14px] text-secondery-color" >({`${index + 1}-${index + 20}`})</span>
            </h4>
          )} */}
          <Level
            level={level}
            isFinnished={user?.game?.solo?.levelsCompletedIds?.includes(level._id)}
            index={index}
          />
        </div>
        {(index !== levels.length - 1) && <span className="w-[10px] h-[10px] bg-light/70 rounded-full block mx-auto mb-2"/>}
        </div>
      ))}
    </div>
  );
};
