import React from 'react'
import { MainButton } from '../button/MainButton'
import { Container } from '../container/Container'
import { Paragraph } from '../text/Paragraph'
import { Title } from '../text/Title'

export const Popup = ({ data }) => {
    const { title, para, onclick, url, label, close } = data;
  return (
    <div className='fixed h-full w-full p-4 z-50 top-0 left-0 bg-dark/60 flex items-center justify-center' onClick={close}>
        <div onClick={(e) => e.stopPropagation()}>
        <Container classnames={'!bg-light/80 relative'} >
            <button onClick={close} className="absolute right-4 top-4 w-8 h-8 text-2xl z-10">X</button>
            <Title title={title}/>
            <Paragraph paragraph={para} classnames={'!text-center'}/>
            {onclick && <MainButton onclick={() => {
                onclick()
                close()
            }} label={label} />}
            {url && <MainButton link={url} label={label} />}
        </Container>
        </div>
    </div>
  )
}
