import classNames from "classnames";
import React, { useState } from "react";
import Select, { components } from 'react-select'



const selectClassNames = {
  clearIndicator: (state) => '',
  container: (state) => 'border-4 border-primary-color rounded-2xl',
  control: (state) => '!rounded-xl py-2 text-xl',
  dropdownIndicator: (state) => '',
  group: (state) => '',
  groupHeading: (state) => '',
  indicatorsContainer: (state) => 'hidden',
  indicatorSeparator: (state) => '',
  input: (state) => '',
  loadingIndicator: (state) => '',
  loadingMessage: (state) => '',
  menu: (state) => '!rounded-2xl overflow-hidden',
  menuList: (state) => '',
  menuPortal: (state) => '',
  multiValue: (state) => '',
  multiValueLabel: (state) => '',
  multiValueRemove: (state) => '',
  noOptionsMessage: (state) => '',
  option: ({ isSelected }) => `${isSelected ? "!bg-primary-color/50 !text-dark" : ""}`,
  placeholder: (state) => '',
  singleValue: (state) => '',
  valueContainer: (state) => 'rounded-3xl'
};



export const SelectInput = ({
  initialValue,
  options,
  classnames,
  onchange,
  isSearchable
}) => {
  const [currValue, setCurrValue] = useState(initialValue);

  const handleChange = (option) => {
    setCurrValue(option);
    onchange(option.value);
  };

  

  return (
    <div className={classNames(classnames)}>
      <Select
            value={currValue}
            onChange={handleChange}
            options={options}
            classNames={selectClassNames}
            styles={{
              control: (base, state) => ({
                ...base,
                border: state.isFocused ? 0 : 0,
                // This line disable the blue border
                boxShadow: state.isFocused ? 0 : 0,
                '&:hover': {
                   border: state.isFocused ? 0 : 0
                }
            })
            }}
            isSearchable={isSearchable}
            components={{ Option }}
        />
    </div>
  );
};

const Option = (props) => {
  const { data, isDisabled, isSelected } = props;

  return (
    <components.Option {...props}>
      <div className="flex items-center">
        {data.icon && <img className="h-6 w-6 rounded-lg" src={data.icon} alt={data.label} />}
        <span className="mx-4">{data.label}</span>
      </div>
    </components.Option>
  );
};