
import React, { useEffect, useState } from "react";
import { useSocket } from "../../../context/useSocket";
import GuessRiddle from "./guess-riddle/GuessRiddle";
import { WaitForAllPlayersToFinnish } from "./WaitForAllPlayersToFinnish";
import { WaitForCurrentPlayerToStart } from "./WaitForCurrentPlayerToStart";

export const Guesser = () => {
  // useState hook to keep track of the current step of the game
  const [step, setStep] = useState(0);

  // useSocket hook to get the current riddle and the endRound function
  const { currentRiddle, endRound } = useSocket();

  // useEffect hook to update the step when the current riddle changes
  useEffect(() => {
    if (currentRiddle.emojis?.length > 0) setStep(1);
  }, [currentRiddle]);

  // render different components depending on the current step of the game
  return (
    <div>
      {step === 0 && <WaitForCurrentPlayerToStart />}
      {step === 1 && <GuessRiddle currentRiddle={currentRiddle} setStep={setStep} finnishRound={endRound} />}
      {step === 2 && <WaitForAllPlayersToFinnish />}
    </div>
  );
};




                