import React from 'react'
import { Container } from '../../container/Container'
import { Title } from '../../text/Title'

export const Scoreboard = ({ users, timer, isFinnishedGame }) => {
  return (
    <Container>
      <Title title={'Score'} />
        {users.sort((a,b) => { return b.score - a.score }).map((user,index) => <div key={index} className={`my-4 flex justify-between items-center`}>
                <h3>{user.name}:</h3>
                <h3>{user.score}</h3>
            </div>)}
        {!isFinnishedGame && <h3 className='w-full text-center text-xl'>Next Round In: {timer}</h3>}
    </Container>
  )
}
