import React from "react";
import { useTranslation } from "react-i18next";

export const Slider = ({
  value,
  setValue,
  name,
  label,
  min = 0,
  max = 100,
  step = 1,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mt-2">
      <label
        htmlFor={name}
        className="text-dark"
      >
        {t(label)}
      </label>
      <input
        id={name}
        type="range"
        value={value}
        onChange={(e) => {
          setValue(+e.target.value);
        }}
        min={min}
        max={max}
        step={step}
        className="w-full h-3 mt-4 bg-light accent-primary-color rounded-lg range-lg appearance-none cursor-pointer"
      />
    </div>
  );
};
