import React, { useEffect } from "react";
import { useAppSound } from "../../../../context/useSound";
import useSound from "use-sound";
import buttonSound from "../../../../assets/sounds/secondery-click.mp3";

export const DisplayChars = ({ chars, moveChar }) => {

  const { volume, isSfx } = useAppSound();

  const [play, { sound }] = useSound(buttonSound, {
    playbackRate: 0.75,
    interrupt: true,
  });

  useEffect(() => {
    if (sound) {
      sound.volume(isSfx ? volume : 0);
    }
  }, [volume, isSfx, sound]);
  return (
    <div className="flex flex-wrap justify-center">
      {chars?.map((char, idx) => (
        <button
          key={idx}
          className={`text-2xl font-semibold w-8 h-8 no-highlight-mobile bg-light/80 rounded-lg mr-2 mb-2 ${char?.isHidden ? 'opacity-0' : ''}`}
          onClick={() => {
            if (isSfx) play()
            moveChar(char, idx)
          }}
          disabled={char?.isHidden}
        >
          {char?.value?.toUpperCase()}
        </button>
      ))}
    </div>
  );
};
