import React from "react";
// import { LeaveButton } from "../../components/button/LeaveButton";
// import { MainButton } from "../../components/button/MainButton";
// import { Container } from "../../components/container/Container";
// import { WaitForPlayersFinnish } from "../../components/game-components/current-player/WaitForPlayersFinnish";
// import { GuessTheRiddle } from "../../components/game-components/guesser/GuessTheRiddle/GuessTheRiddle";
// import { WaitForAllPlayersToFinnish } from "../../components/game-components/guesser/WaitForAllPlayersToFinnish";
// import { WaitForCurrentPlayerToStart } from "../../components/game-components/guesser/WaitForCurrentPlayerToStart";
// import { Scoreboard } from "../../components/game-components/scoreboard/Scoreboard";
// import { Paragraph } from "../../components/text/Paragraph";
// import { Title } from "../../components/text/Title";
// import dataMock from "../../dataMock.json";
// import { ChooseGroupGameOptions } from "../ChooseGroupGameOptions/ChooseGroupGameOptions";
import { GroupGame } from "../Group/GroupGame";

export const Example = () => {
  // const [currentAnswer] = useState("");
  // const [currentemojis, setCurrentemojis] = useState([]);
  // const setAnswer = () => {

  // } 
  // const setStep = () => {

  // }

  // const handleNextStep = () => {
  //   setAnswer({
  //     answer: currentAnswer,
  //     emojis: currentemojis.map(emoji => ({ ...emoji, imageUrl: emoji.getImageUrl() })),
  //   });
  //   setStep(2)
  // };

  // const handleemoji = (value) => {
  //   // const emojiRegex = /\p{Extended_Pictographic}/gu;
  //   // const isemoji = emojiRegex.test(value);
  //   // console.log(isemoji, value.getImageUrl());
  //   // if (isemoji || !value) setCurrentemojis(value);
  //   setCurrentemojis(prev => ([ ...prev, value ]))
  // };

  
  return (
    <>
   
    {/* <Container page> */}
      {/* <LeaveButton />
      <Container>
      <Title title="Emoji Who" />
      <Paragraph paragraph="Emoji Who is a mobile game where players try to guess the word or phrase represented by a series of emojis. It gets harder as players progress. It's fun and colorful with easy controls." />
      </Container>

      <Container>
      <Title title="Emoji Who" />
      <Paragraph paragraph="Emoji Who is a mobile game where players try to guess the word or phrase represented by a series of emojis. It gets harder as players progress. It's fun and colorful with easy controls." />
      <MainButton label={'Start'} />
      </Container> */}


<GroupGame/>

    {/* </Container> */}
    </>
  );
};
