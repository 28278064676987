import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { forgotPassword } from "../../../api/userApi";
import { LeaveButton } from "../../../components/button/LeaveButton";
import { MainButton } from "../../../components/button/MainButton";
import { Container } from "../../../components/container/Container";
import { ErrorMessage } from "../../../components/errors/ErrorMessage";
import { MainInput } from "../../../components/input/MainInput";
import { Loader } from "../../../components/loader/Loader";
import { Paragraph } from "../../../components/text/Paragraph";
import { Title } from "../../../components/text/Title";
import { urls } from "../../../globalConsts";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [confirmed, setConfirmed] = useState(false)
  const { t } = useTranslation();
  const { isLoading, isError, error, refetch } = useQuery(
    "forgot-password",
    async () => await forgotPassword(email),
    {
      enabled: false,
      onSuccess: (data) => {
        setConfirmed(true)
      }
    }
  );

  return (
    <Container page >
      <LeaveButton toUrl={urls.login} />
      <Container>
        {confirmed ? <>
          <Title title={t('forgot-password.all-done')} />
        <Paragraph
          paragraph={t('forgot-password.check-email')}
          classnames={"text-center"}
        />
        </> 
        :
        <>
        <Title title={`${t('forgot-password.forgot-password')}?`} />
        <Paragraph
          paragraph={t('forgot-password.send-link')}
          classnames={"text-center !text-dark"}
        />
        <div className="mt-4">
          {isError && (
            <ErrorMessage
              text={error?.response?.data?.message ? error?.response?.data?.message : t('errors.somthing-went-wrong')}
              
            />
          )}

          <MainInput
            // label={"Email Address"}
            type={t('common.email')}
            name={"email"}
            value={email}
            id="email"
            placeholder={t('common.email-address')}
            onchange={(e) => setEmail(e.target.value)}
            isError={isError} 
          />

          {isLoading ? (
           <div className="w-full flex justify-center"> <Loader /></div>
          ) : (
            <MainButton onclick={refetch} label={t('common.reset')} />
          )}
        </div>
        </>}
      </Container>
    </Container>
  );
};
