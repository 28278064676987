export const copyToClipboard = (text) => {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  export const getFontFamily = (locale) => {
    switch (locale) {
      case 'en':
        return 'Main, sans-serif';
      case 'he':
        return 'He, sans-serif';
      default:
        return 'sans-serif';
    }
  }