import { Navigate } from "react-router-dom";
import { AuthContainer } from "./components/auth/auth-container/AuthContainer";
import { SocketContainer } from "./components/container/SocketContainer";

// flags

import USFLAG from "./assets/flags/us.svg";
import ILFLAG from "./assets/flags/il.svg";
import RUFLAG from "./assets/flags/ru.svg";

// Routes

import { ConfirmEmail } from "./pages/auth/confirm/ConfirmEmail";
import { ForgotPassword } from "./pages/auth/forgotPassword/ForgotPassword";
import { Login } from "./pages/auth/login/Login";
import { Register } from "./pages/auth/register/Register";
import { ResetPassword } from "./pages/auth/resetPassword.js/ResetPassword";
import { ChooseGroupGameOptions } from "./pages/ChooseGroupGameOptions/ChooseGroupGameOptions";
import { Compare } from "./pages/Compare/Compare";
import { EnterUser } from "./pages/EnterUser/EnterUser";
import { Error } from "./pages/Error/Error";
import { NoSocketConnection } from "./pages/Error/NoSocketConnection";
import { Example } from "./pages/Example/Example";
// import { Game } from "./pages/Game/Game";
import { GameChoose } from "./pages/GameChoose/GameChoose";
import { GroupGame } from "./pages/Group/GroupGame";
import { Home } from "./pages/Home/Home";
import { Lobby } from "./pages/Lobby/Lobby";
import { PrivacyPolicy } from "./pages/privacy/PrivacyPolicy";
import { Profile } from "./pages/Profile/Profile";
import { Settings } from "./pages/settings/Settings";
import { SoloGame } from "./pages/Solo/SoloGame";
import { SoloRiddle } from "./pages/Solo/SoloRiddle";
import { TypeChoose } from "./pages/Solo/TypeChoose";
import { Terms } from "./pages/terms/Terms";
import { Store } from "./pages/store/Store";
import { LevelChoose } from "./pages/Solo/LevelChoose";
import { Feedback } from "./pages/feedback/Feedback";

export const routes = [
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <Error />,
  },
  {
    path: "/register",
    element: <Register />,
    errorElement: <Error />,
  },
  {
    path: "/email-confirm/:key",
    element: <ConfirmEmail />,
    errorElement: <Error />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    errorElement: <Error />,
  },
  {
    path: "/reset-password/:key",
    element: <ResetPassword />,
    errorElement: <Error />,
  },
  {
    path: "/enter-user",
    element: (
      <SocketContainer>
        <EnterUser />
      </SocketContainer>
    ),
    errorElement: <Error />,
  },
  {
    path: "/lobby",
    element: (
      <SocketContainer>
        <Lobby />
      </SocketContainer>
    ),
    errorElement: <Error />,
  },
  {
    path: "/game-options",
    element: (
      <SocketContainer>
        <ChooseGroupGameOptions />
      </SocketContainer>
    ),
    errorElement: <Error />,
  },
  {
    path: "/game/:roomId",
    element: (
      <SocketContainer>
        <GroupGame />
      </SocketContainer>
    ),
    errorElement: <Error />,
  },
  // {
  //   path: "/game/:roomId",
  //   element: (
  //     <SocketContainer>
  //       <Game />
  //     </SocketContainer>
  //   ),
  //   errorElement: <Error />,
  // },
  {
    path: "/example",
    element: <Example />,
    errorElement: <Error />,
  },
  {
    path: "/compare",
    element: <Compare />,
    errorElement: <Error />,
  },
  {
    path: "/solo-choose",
    element: (
      <AuthContainer>
        <TypeChoose />
      </AuthContainer>
    ),
    errorElement: <Error />,
  },
  {
    path: "/solo/:type/level-choose",
    element: (
      <AuthContainer>
        <LevelChoose />
      </AuthContainer>
    ),
    errorElement: <Error />,
  },
  {
    path: "/solo/:type/:level",
    element: (
      <AuthContainer>
        <SoloGame />
      </AuthContainer>
    ),
    errorElement: <Error />,
  },
  {
    path: "/solo/:type/:level/:index",
    element: (
      <AuthContainer>
        <SoloRiddle />
      </AuthContainer>
    ),
    errorElement: <Error />,
  },
  {
    path: "/choose-game",
    element: <GameChoose />,
    errorElement: <Error />,
  },
  {
    path: "/profile",
    element: (
      <AuthContainer>
        <Profile />
      </AuthContainer>
    ),
    errorElement: <Error />,
  },
  {
    path: "/settings",
    element: <Settings />,
    errorElement: <Error />,
  },
  {
    path: "/connection-error",
    element: <NoSocketConnection />,
    errorElement: <Error />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    errorElement: <Error />,
  },
  {
    path: "/terms",
    element: <Terms />,
    errorElement: <Error />,
  },
  {
    path: "/store",
    element: <AuthContainer><Store /></AuthContainer>,
    errorElement: <Error />,
  },
  {
    path: "/feedback",
    element: <Feedback />,
    errorElement: <Error />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

export const urls = {
  home: "/",
  login: "/login",
  register: "/register",
  chooseGame: "/choose-game",
  settings: "/settings",
  profile: "/profile",
  privacy: "/privacy-policy",
  terms: "/terms",
  forgotPassword: "/forgot-password",
  soloChoose: "/solo-choose",
  lobby: "/lobby",
  enterUser: "/enter-user",
  solo: "/solo",
  sotre: "/store",
  feedback: "/feedback"
};

// App
export const APP_NAME = "Emoji What";

// Classes

export const backgroundScreen = "backdrop-blur-sm bg-white/30 p-4 rounded-md";

// Game

export const initialSocketUser = {
  currentRoomId: "",
  name: "",
  avatar: "",
  score: 0,
  isFinnishedRound: false,
  isPlaying: false,
  isCreator: false,
};

export const gameCategories = [
  {
    value: "movies",
    icon: "",
    isAvailable: true,
  },
  {
    value: "tv-shows",
    icon: "",
    isAvailable: false,
  },
  {
    value: "music",
    icon: "",
    isAvailable: false,
  },
  {
    value: "games",
    icon: "",
    isAvailable: false,
  },
];

// Game

export const gameNumberOfRoundOptions = [
  { value: 1, isAvailable: true },
  { value: 2, isAvailable: true },
  { value: 3, isAvailable: true },
  { value: 4, isAvailable: true },
];

export const roundTimeInSeconds = 60;
export const timeBetweenRoundsInSeconds = 15;

export const coinsPerLevel = 10;

// solo types

export const soloGameTypes = [
  {
    name: "movies"
  },
  {
    name: "tv-shows"
  },
  {
    name: "music"
  },
]

// User

// eslint-disable-next-line no-template-curly-in-string
export const initialAvatar = "https://api.multiavatar.com/${avatarName}.svg";

// Theme


// Lanuage

// languages

export const langChars = {
  en: 'abcdefghijklmnopqrstuvwxyz',
  he: 'אבגדהוזחטיכלמנסעפצקרשת',
  ru: 'абвгдеёжзийклмнопрстуфхцчшщъыьэюя'
}

// rtl langs
export const rtlLanguages = ["he"];

export const languageOptions = [
  { value: "en", icon: USFLAG },
  { value: "he", icon: ILFLAG },
  { value: "ru", icon: RUFLAG },
];


export const tvShows = [

]


