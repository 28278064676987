import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getLevels } from "../../api/gameApi";
import { LeaveButton } from "../../components/button/LeaveButton";
import { Container } from "../../components/container/Container";
import { Levels } from "../../components/game-components/levels/Levels";
import { Paragraph } from "../../components/text/Paragraph";
import { Title } from "../../components/text/Title";
import { useData } from "../../context/useData";
import data from "../../constants/data/levels-full.json";
import { Loading } from "../loading/Loading";

export const SoloGame = () => {
  // const [levels, setLevels] = useState([])
  const { t } = useTranslation();
  const { type, level } = useParams();
  const { user, refetchUser, loadingUser } = useData();
  const navigate = useNavigate();

  const levels = data["levels"].filter(currentLevel => currentLevel.category === type && currentLevel.level == level);

  // const {
  //   isLoading: loadingLevels,
  //   data: levels,
  //   isError: levelsError,
  // } = useQuery("get-levels", () => getLevels(type, level), {
  //   onSuccess: (data) => {
  //     // setLevels(data)
  //     if (data.length < 1) navigate(`/solo/${type}/level-choose`)
  //   },
  // });

  useEffect(() => {
    if (user && user !== "pending") {
      (async () => {
        // await refetchUser(user);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if (true) return <Loading />;
  if (loadingUser) return <Loading />;


  return (
    <Container page classnames={""}>

      <LeaveButton toUrl={`/solo/${type}/level-choose`} />

      <div className="flex flex-col">
        <Title title={t(`solo-game.${type}`)} />

        <div className="w-full flex-1 mx-auto overflow-y-auto mt-4">
          <Levels levels={levels} type={type} level={level} />
          {/* <Levels levels={data} type={type} /> */}
        </div>
      </div>
    </Container>
  );
};
