import React from 'react'
import { useTranslation } from 'react-i18next'
import { MainButton } from '../../components/button/MainButton'
import { Container } from '../../components/container/Container'
import { Paragraph } from '../../components/text/Paragraph'
import { Title } from '../../components/text/Title'
import { urls } from '../../globalConsts'

export const Error = () => {
  const { t } = useTranslation();

  return (
    <Container page noHeader>
        <Container>
            <Title title={t('errors.error-occurred')}/>
            <Paragraph paragraph={t('errors.somthing-went-wrong')}/>
            <Paragraph paragraph={t('errors.try-later')}/>
            <MainButton link={urls.home} label={t('common.try-again')}/>
        </Container>
    </Container>
  )
}
