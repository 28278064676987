import { searchMovies } from "../api/gameApi";

export const transformGoogleUser = (googleUser) => {
  return {
    fullName: googleUser?.names[0]?.displayName,
    nickName:
      googleUser?.names[0].givenName +
      googleUser?.names[0].familyName.split("")[0],
    email: googleUser?.emailAddresses[0].value,
    avatar: googleUser?.photos[0].url,
  };
};

export const generateRandomString = () => {
  var length = Math.floor(Math.random() * (16 - 4 + 1)) + 4; // random length between 4 and 16
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

export const cleanMovieName = (name) => {
  let cleanedName;
  let currentName = name;

  // take only the movie series name
  if (name.includes(":")) {
    const splitName = name.split(":");
    currentName = splitName[0];
  }

  // keep only letters
  cleanedName = currentName.replace(/[^a-zA-Z ]/g, "");


  // remove extra space at end
  if (cleanedName[cleanedName.length - 1] === " ") {
    cleanedName = cleanedName.slice(0, -1);
  }

  return cleanedName;
};

export const getFormatedMovies = (initialMovies = [], currentAnswer) => {
  
  // clean movie name
  let cleanedMovies = initialMovies?.map((movie) =>
    cleanMovieName(movie.title)
  );

  // remove duplicates && to long name && none match names
  cleanedMovies = [
    ...new Set(
      cleanedMovies.filter((movieName) => {
        return (
          movieName.split(" ").length < 6 &&
          movieName.toLowerCase().includes(currentAnswer.toLowerCase())
        );
      })
    ),
  ];

  return cleanedMovies;

};


export const getCategoryFormatedOptions = (category) => {

switch (category) {
  case "movie": 
    return getFormatedMovies;
  default:
    return getFormatedMovies;

}
}

export const getFetchOptions = (category, query) => {
  switch (category) {
    case "movie":
      return searchMovies(query);
    default:
      return searchMovies(query);
  }
} 