import React, { useState } from "react";
import Logo from "../../assets/logos/logo.png";
import LogoMin from "../../assets/logos/logo-min.png";

import { useWindowSize } from "../../hooks/useWIndowSize";

export const HomeLogo = () => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const [width] = useWindowSize();
  const style = {
    width: `${width * 0.8}px`,
    height: `30vh`,
    // height: `${height * 0.4}px`,
    "@media (maxWidth: 600px)": {
      width: `${width * 0.7}px`,
      // height: `${height * 0.3}px`,
    },
    "@media (maxWidth: 400px)": {
      width: `${width * 0.7}px`,
      // height: `${height * 0.3}px`,
    },
  };

  const handleImageLoad = () => {
    setIsImageLoading(false);
  };
  
  return (
    <>
      {isImageLoading && <img src={LogoMin} className="object-contain h-[35vh] ml-6 w-[100%]" />}
      <img src={Logo} onLoad={handleImageLoad} style={{display: isImageLoading ? 'none' : ''}} className="object-contain h-[35vh] ml-6 w-[100%]" />
    </>
  );
};
