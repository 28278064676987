import axios from "axios";
import { SERVER_URL } from "../config";
const MOVIES_API_BASE_URL = process.env.REACT_APP_MOVIES_API_BASE_URL;
const MOVIES_API_KEY = process.env.REACT_APP_MOVIES_API_KEY;

export const getLevels = async (type, level) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}/api/game/levels`, {
      type,
      level
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getGameTypeLevels = async (type, userId) => {
  if (!type || !userId) return
  try {
    const { data } = await axios.post(`${SERVER_URL}/api/game/levels-amount`, {
      type,
      userId
    });
    return data;
  } catch (error) {
    return error;
  }
};



export const completeLevel = async (userId, levelId, coins, type, level) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}/api/game/complete-level`, {
      userId,
      levelId,
      coins,
      type, 
      level
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const revealChar = async (userId) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}/api/game/use-reveal`, {
      userId,
    });
    return data;
  } catch (error) {
    throw new Error("Network Error");
  }
};

export const removeChar = async (userId) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}/api/game/use-remove`, {
      userId,
    });
    return data;
  } catch (error) {
    throw new Error("Network Error");
  }
};

export const saveLevelState = async (
  hiddenAnswer,
  charsToGuess,
  levelId,
  userId,
  language
) => {
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/game/save-level-state`,
      {
        hiddenAnswer,
        charsToGuess,
        levelId,
        userId,
        language
      }
    );
    return data;
  } catch (error) {
    throw new Error("Network Error");
  }
};

// export const getLevelState = async (
//   levelId,
//   userId
// ) => {
//   try {
//     const { data } = await axios.post(
//       `${SERVER_URL}/api/game/get-level-state`,
//       {
//         levelId,
//         userId,
//       }
//     );
//     return data;
//   } catch (error) {
//     throw new Error("Network Error");
//   }
// };

export const getHelps = async (
  userId,
  actionType
) => {
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/api/game/get-helps`,
      {
        userId,
        actionType
      }
    );
    return data;
  } catch (error) {
    throw new Error("Network Error");
  }
};


// Handle search query for movies
export const searchMovies = async (query) => {
  const { data } = await axios.get(
    `${MOVIES_API_BASE_URL}/search/movie?api_key=${MOVIES_API_KEY}&query=${query}&language=en-US&page=1&include_adult=false`
  );
  return data;
};
