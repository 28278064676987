import { useTranslation } from "react-i18next";
import { Container } from "../../container/Container";
import { Title } from "../../text/Title";

export const WaitForCurrentPlayerToStart = () => {
  const { t } = useTranslation();
    return (
      <Container>
        <Title title={t('gameRoom.!isFinnishedGame.!isFinnishedRound.Guesser.WaitForCurrentPlayerToStart.title')} />
      </Container>
    );
  };