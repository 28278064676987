import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { urls } from '../../globalConsts';
import { useData } from '../../context/useData';
import { Avatar } from '../avatar/Avatar';

// assets
import {ReactComponent as SettingsIcon} from '../../assets/svg-icons/settings.svg';
import {ReactComponent as UserIcon} from '../../assets/svg-icons/user-circle.svg';
import {ReactComponent as TermsIcon} from '../../assets/svg-icons/terms.svg';
import {ReactComponent as PrivacyIcon} from '../../assets/svg-icons/privacy.svg';
import {ReactComponent as GameIcon} from '../../assets/svg-icons/game.svg';
import {ReactComponent as LoginIcon} from '../../assets/svg-icons/login.svg';
import {ReactComponent as StoreIcon} from '../../assets/svg-icons/store.svg';
import {ReactComponent as FeedbackIcon} from '../../assets/svg-icons/feedback.svg';
import { useAppSound } from '../../context/useSound';
import useSound from 'use-sound';
import buttonSound from "../../assets/sounds/main-click.mp3";


export const HeaderMenu = ({ menuIsOpen, setMenuIsOpen, isRtl }) => {
    const { t } = useTranslation();
    const { user } = useData();
    const linkClassName = 'w-full flex items-center py-4 shadow-border';

    const { volume, isSfx } = useAppSound();

    const [play, { sound }] = useSound(buttonSound, {
      playbackRate: 0.75,
      interrupt: true,
    });
  
    useEffect(() => {
      if (sound) {
        sound.volume(isSfx ? volume : 0);
      }
    }, [volume, isSfx, sound]);

    const handlePlaySound = () => {
      if (isSfx) play()
    }

  return (
    <div className={`absolute w-full h-full top-0 left-0 transition-all ease-in-out duration-300  ${menuIsOpen ? 'z-30' : '-z-20'}`}>
        <div className={`w-full h-full bg-dark/60 relative transition-opacity ease-in-out duration-300 ${menuIsOpen ? '' : 'opacity-0'}`} onClick={() => setMenuIsOpen(false)}></div>
        <div className={`w-[70%] max-w-[450px] h-full min-w-[220px] bg-light/95 p-4 flex flex-col transition-all ease-in-out duration-300 absolute top-[60px] overflow-y-auto pb-[100px]`}
        style={menuIsOpen ? (isRtl ? { right: 0, height: `calc(100vh - 60px)` } : { left: 0, height: `calc(100vh - 60px)` }): (isRtl ?  { right: "-100%", height: `calc(100vh - 60px)` } : { left: "-100%", height: `calc(100vh - 60px)` })}
        >
          <div className='flex flex-col items-center mt-6 w-full shadow-border pb-6'>
          <Avatar imageUrl={user?.avatar} size={'md'} />
          <p className='mt-2 text-sm'>{user?.nickName ? user?.nickName : t('common.guest')}</p>
        </div>
            <Link className={linkClassName} to={urls.chooseGame} onClick={handlePlaySound}><GameIcon /><span className='mx-2'>{t('screens.game')}</span></Link>
            <Link className={linkClassName} to={urls.profile} onClick={handlePlaySound}><UserIcon /><span className='mx-2'>{t('screens.profile')}</span></Link>
            <Link className={linkClassName} to={urls.sotre} onClick={handlePlaySound}><StoreIcon /><span className='mx-2'>{t('screens.store')}</span></Link>
            <Link className={linkClassName} to={urls.settings} onClick={handlePlaySound}><SettingsIcon /><span className='mx-2'>{t('screens.settings')}</span></Link>
            <Link className={linkClassName} to={urls.login} onClick={handlePlaySound}><LoginIcon /><span className='mx-2'>{t('screens.login')}</span></Link>
            <Link className={linkClassName} to={urls.feedback} onClick={handlePlaySound}><FeedbackIcon /><span className='mx-2'>{t('screens.feedback')}</span></Link>
            <Link className={linkClassName} to={urls.terms} onClick={handlePlaySound}><TermsIcon /><span className='mx-2'>{t('screens.terms')}</span></Link>
            <Link className={linkClassName} to={urls.privacy} onClick={handlePlaySound}><PrivacyIcon /><span className='mx-2'>{t('screens.privacy')}</span></Link>
        </div>
    </div>
  )
}
