import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import {
  registerUser,
  validateEmail,
  validateNickname,
} from "../../../api/userApi";
import { MainButton } from "../../../components/button/MainButton";
import { Container } from "../../../components/container/Container";
import { ErrorMessage } from "../../../components/errors/ErrorMessage";
import { MainInput } from "../../../components/input/MainInput";
import { Loader } from "../../../components/loader/Loader";
import { Paragraph } from "../../../components/text/Paragraph";
import { Title } from "../../../components/text/Title";
import { urls } from "../../../globalConsts";

const initialRegisterForm = {
  fullName: "",
  nickName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const Register = () => {
  const [registerForm, setRegisterForm] = useState(initialRegisterForm);
  const [isAgree, setIsAgree] = useState(false);
  const [errorMessage, setError] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [loadingValidation, setLoadingValidation] = useState(false);

  const { t } = useTranslation();

  const { isLoading, isError, data, error, refetch } = useQuery(
    "register",
    () => registerUser(registerForm),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (data && data.status === 201) {
      setConfirmed(true);
    }
  }, [data]);

  const handleChange = (event) => {
    setError("");
    const { name, value } = event.target;
    setRegisterForm({ ...registerForm, [name]: value });
  };

  const handleSubmit = async () => {
    setError("");
    let isValid = true;
    setLoadingValidation(true);
    // Validation logic
    if (
      !registerForm.fullName ||
      !registerForm.nickName ||
      !registerForm.email ||
      !registerForm.password ||
      !registerForm.confirmPassword
    ) {
      setError("All fields are reqiured");
      setLoadingValidation(false);
      return (isValid = false);
    }
    if (registerForm.nickName) {
      try {
        const validated = await validateNickname(registerForm.nickName);
        if (!validated) {
          setError(t("errors.register.nickname"));
          setLoadingValidation(false);
          return (isValid = false);
        }
      } catch (err) {
        // setError("Somthing went wrong");
        setLoadingValidation(false);
      }
    }
    if (registerForm.email) {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      if (!emailRegex.test(registerForm.email)) {
        setError(t("errors.register.valid-email"));
        setLoadingValidation(false);
        return (isValid = false);
      }
      try {
        const validated = await validateEmail(registerForm.email);
        if (!validated) {
          setError(t("errors.register.email-taken"));
          setLoadingValidation(false);
          return (isValid = false);
        }
      } catch (err) {
        console.log("register-email-error", err);
        // setError("Somthing went wrong")
        setLoadingValidation(false);
      }
    }
    if (registerForm.password) {
      if (registerForm.password.length < 6) {
        setError(t("errors.register.short-password"));
        setLoadingValidation(false);
        return (isValid = false);
      } else if (registerForm.password !== registerForm.confirmPassword) {
        setError(t("errors.register.password-match"));
        setLoadingValidation(false);
        return (isValid = false);
      }
    }
    setLoadingValidation(false);
    if (isValid) {
      await refetch();
    }
  };

  return (
    <Container page>
      <Container>
        {confirmed ? (
          <>
            <Title title={t("register.all-done")} />
            <Paragraph
              paragraph={t("register.confirm-account")}
              classnames={"text-center"}
            />
          </>
        ) : (
          <div className={" w-[100%]"}>
            <p className="text-2xl w-full text-center text-dark mb-2">
              {t("register.fill-form")}
            </p>

            <div className="mt-6">
              <MainInput
                label={t("register.full-name")}
                type={"text"}
                name={"fullName"}
                value={registerForm["fullName"]}
                id="fullName"
                placeholder={t("register.full-name")}
                onchange={handleChange}
                isError={isError}
              />
              <MainInput
                label={t("register.nickname")}
                type={"text"}
                name={"nickName"}
                value={registerForm["nickName"]}
                id="nickname"
                placeholder={t("register.nickname")}
                onchange={handleChange}
                isError={isError}
              />
              <MainInput
                label={t("common.email")}
                type={"email"}
                name={"email"}
                value={registerForm["email"]}
                id="email"
                placeholder={t("login.email-address")}
                onchange={handleChange}
                isError={isError}
              />
              <MainInput
                label={t("common.password")}
                type={"password"}
                name={"password"}
                value={registerForm["password"]}
                id="password"
                placeholder={t("common.password")}
                onchange={handleChange}
                isError={isError}
              />
              <MainInput
                label={t("register.confirm-password")}
                type={"password"}
                name={"confirmPassword"}
                value={registerForm["confirmPassword"]}
                id="confirmPassword"
                placeholder={t("register.confirm-password")}
                onchange={handleChange}
                isError={isError}
              />
            </div>

            <div className="flex justify-between items-center mb-6">
              <div className="form-group form-check flex items-center">
                <input
                  type="checkbox"
                  className="form-check-input appearance-none h-5 w-5 border-2 border-primary-color rounded-md bg-white checked:bg-primary-color checked:border-white focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer"
                  id="agree-terms"
                  onClick={() => setIsAgree(!isAgree)}
                />
                <label
                  className="form-check-label inline-block text-gray-800 mx-2"
                  htmlFor="exampleCheck2"
                >
                  {t("register.agree-to")}
                  <Link to={urls.terms} className="text-dark underline mx-2">
                    {t("common.terms")}
                  </Link>
                </label>
              </div>
            </div>

            {errorMessage && <ErrorMessage text={errorMessage} />}
            {isError && (
              <ErrorMessage
                text={
                  // error?.response?.data?.message
                  //   ? error?.response?.data?.message
                  //   :
                  t("errors.somthing-went-wrong")
                }
              />
            )}

            <div className="text-center lg:text-left">
              {isLoading || loadingValidation ? (
                <Loader />
              ) : (
                <MainButton
                  label={t("common.register")}
                  onclick={handleSubmit}
                  disabled={!isAgree}
                />
              )}

              <div className="flex items-center mt-6 pb-2">
                <p className="text-md  text-dark  mb-0">
                  {t("register.have-account")}?
                </p>
                <Link
                  to={urls.login}
                  className="text-secondery-color underline hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out mx-2"
                >
                  {t("common.login")}
                </Link>
              </div>
            </div>
          </div>
        )}
      </Container>
    </Container>
  );
};
