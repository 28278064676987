import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSound } from "../../context/useSound";
import useSound from "use-sound";
import buttonSound from "../../assets/sounds/secondery-click.mp3";


export const Checkbox = ({ value, setValue, text }) => {
    const { t } = useTranslation();

    const { volume, isSfx } = useAppSound();

    const [play, { sound }] = useSound(buttonSound, {
        playbackRate: 0.75,
        interrupt: true,
      });
    
      useEffect(() => {
        if (sound) {
          sound.volume(isSfx ? volume : 0);
        }
      }, [volume, isSfx, sound]);


  return (
    <div className="flex items-center mt-2">
      <input
        type="checkbox"
        className="form-check-input appearance-none h-5 w-5 border-2 border-primary-color rounded-md bg-white checked:bg-primary-color checked:border-white focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left m-0 cursor-pointer"
        id="forget-me"
        checked={value}
        onChange={() => { 
            if (isSfx) play()
            setValue()
        }}
      />
      <label
        className={`form-check-label inline-block text-dark mx-2  ${
            value ? "border-primary-color" : ""
        }`}
      >
        {t(text)}
      </label>
    </div>
  );
};
