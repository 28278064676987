import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "../../components/container/Container";
import { HomeLogo } from "../../components/logo/HomeLogo";
import { MainButton } from "../../components/button/MainButton";
import { useData } from "../../context/useData";
import { useSocket } from "../../context/useSocket";
import { checkIfRoomExists } from "../../api/socketApi";
import { Paragraph } from "../../components/text/Paragraph";
import { useTranslation } from "react-i18next";
import { urls } from "../../globalConsts";

export const Home = () => {
  const navigate = useNavigate();
  const { user, setHandlePopup } = useData();
  const { socketUser, setSocketUser, handleJoinRoom } = useSocket();
  const { t } = useTranslation();

  // Handle rejoin existing game
  useEffect(() => {
    if (socketUser) {
      (async () => {
        const isRoomExist = await checkIfRoomExists(socketUser.currentRoomId);
        if (isRoomExist) {
          setHandlePopup({
            showPopup: true,
            popupData: {
              title: "Active Room",
              para: "Looks like you disconnected, would you like to rejoin the room?",
              onclick: async () => {
                const isOk = await handleJoinRoom(socketUser.currentRoomId);
                if (isOk) navigate(`/game/${socketUser.currentRoomId}`);
              },
              label: "rejoin",
              onclose: () => setHandlePopup({ showPopup: false }),
            },
          });
        } else setSocketUser(null);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketUser]);

  return (
    <Container page>
      <div className="flex flex-col items-center">
        <HomeLogo />
      </div>

      <div className="w-full flex flex-col items-center mt-2">
        <Container>
          <>
            <div className="bg-secondery-color p-1 w-fit mt-2 mx-auto rounded">

            <Paragraph
              paragraph={`${t("common.beta")}`}
              // paragraph={`${t('home.to-enjoy-more-content')}:`}
              classnames={" !text-sm !text-light !font-bold !m-0"}
            />
            </div>
            <Paragraph
              paragraph={`${t("home.welcome-header")}`}
              // paragraph={`${t('home.to-enjoy-more-content')}:`}
              classnames={" !text-2xl "}
            />
            <Paragraph
              paragraph={`${t("home.welcome-body")}`}
              // paragraph={`${t('home.to-enjoy-more-content')}:`}
              classnames={""}
            />
            {!user ? (
              <>
                <MainButton link={urls.login} label={t("screens.login")} />
                <div className="flex items-center w-full mt-4">
                  <Paragraph
                    paragraph={t("home.dont-have-account")}
                    classnames={" !text-md !m-0 !text-start !w-fit"}
                  />
                  <Link
                    className="text-[#F95541] text-md underline mx-2"
                    to={urls.register}
                  >
                    {t("home.create-account")}
                  </Link>
                </div>
              </>
            ) : (
              <>
                <MainButton link={urls.chooseGame} label={t("common.play")} />
              </>
            )}
          </>
        </Container>
      </div>
    </Container>
  );
};
