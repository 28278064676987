import axios from 'axios';
import { SERVER_URL } from '../config';

export const saveSocketUser = async (socketUser) => {
    try {
        // const savedSocketUser = await axios.get(`${SERVER_URL}/socket-api/socket/status`);
        const { data } = await axios.post(`${SERVER_URL}/socket-api/socket/create-socket-user`, socketUser);
        return data;
    } catch (error) {
        console.log(error)
    }
} 

export const removeSocketUser = async (socketUserId) => {
    try {
        // const savedSocketUser = await axios.get(`${SERVER_URL}/socket-api/socket/status`);
        const { data } = await axios.post(`${SERVER_URL}/socket-api/socket/remove-socket-user`, { socketUserId });
        console.log(data)
        return data;
    } catch (error) {
        console.log(error)
    }
}   

export const updateSocketUser = async (socketUser) => {
    try {
        // const savedSocketUser = await axios.get(`${SERVER_URL}/socket-api/socket/status`);
        const { data } = await axios.post(`${SERVER_URL}/socket-api/socket/update-socket-user`, socketUser);
        return data;
    } catch (error) {
        console.log(error)
    }
}  

export const checkIfRoomExists = async (roomId) => {
    try {
        // const savedSocketUser = await axios.get(`${SERVER_URL}/socket-api/socket/status`);
        const { data } = await axios.post(`${SERVER_URL}/socket-api/socket/check-room-exists`, { roomId });
        return data;
    } catch (error) {
        console.log(error)
    }
}   