import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import {  getCategoryFormatedOptions, getFetchOptions } from "../../../services/dataManipulation";
import { MainButton } from "../../button/MainButton";
import { MainInput } from "../../input/MainInput";
import { Loader } from "../../loader/Loader";
import { Title } from "../../text/Title";
import emojiRegex from 'emoji-regex';
import { useTranslation } from "react-i18next";


export const ChooseRiddleStep = ({ setAnswer, setStep, category }) => {
  const [optionAnswer, setOptionAnswer] = useState("");
  const [currentEmojis, setCurrentEmojis] = useState("");
  const [categoryName] = useState(category?.slice(0, category?.length - 1));

  const { t } = useTranslation()

  // handle options for search query fetch
  const { data, isLoading: loadingOptions, isError } = useQuery(
    [category, optionAnswer],
    () => getFetchOptions(category, optionAnswer),
    { enabled: Boolean(optionAnswer) }
  );


  // hanle format the options data for rendering
  const formatCategoryOptions = useCallback(
    (data, answer) => { 
      const getOptions = getCategoryFormatedOptions(categoryName);
      return getOptions(data, answer);
    },
    [categoryName]
  );

  // complete selecting and handle next step
  const handleNextStep = () => {
    setAnswer({
      answer: optionAnswer,
      emojis: currentEmojis,
      category: category,
    });
    setStep(2);
  };

  // handle emoji selection
  const handleEmoji = (value) => {
    // const emojiRegex =
    //   /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/;
    const isEmoji = emojiRegex().test(value);
    if (isEmoji || value === "") setCurrentEmojis(value);
  };

  return (
    <div>
      {/* <ChooseFromCategory
        categoryName={categoryName}
        setAnswer={setOptionAnswer}
        options={formatCategoryOptions(data?.results, optionAnswer)}
        loadingOptions={loadingOptions}
        isError={isError}
      /> */}
       <Title  title={t(`gameRoom.!isFinnishedGame.!isFinnishedRound.CurrentPlayer.ChooseRiddleStep.ChooseFromCategory.title`) + " " + t(`categories.${categoryName}`)} classnames={"mt-4"} />
      <div className="mt-4 w-full">
        <MainInput
          type={"text"}
          name={"answer"}
          value={optionAnswer}
          id="emojis"
          placeholder={t(`gameRoom.!isFinnishedGame.!isFinnishedRound.CurrentPlayer.ChooseRiddleStep.ChooseFromCategory.placeholder`) + " " + t(`categories.${categoryName}`)}
          onchange={(e) => setOptionAnswer(e.target.value)}
          isError={false}
        />
      </div>


      <Title title={`Select emojis`} classnames={"mt-4"} />
      <div className="mt-4 w-full">
        <MainInput
          type={"text"}
          name={"emojis"}
          value={currentEmojis}
          id="emojis"
          placeholder={`Select Emojis`}
          onchange={(e) => handleEmoji(e.target.value)}
          isError={false}
        />
      </div>

      <MainButton
        onclick={handleNextStep}
        disabled={!optionAnswer || !currentEmojis}
        label="START"
      />
    </div>
  );
};

const ChooseFromCategory = ({
  categoryName,
  setAnswer,
  options,
  loadingOptions,
  isError
}) => {
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const { t } = useTranslation()

  return (
    <div>
      <Title 
      title={t(`gameRoom.!isFinnishedGame.!isFinnishedRound.CurrentPlayer.ChooseRiddleStep.ChooseFromCategory.title`) + " " + t(`categories.${categoryName}`)}
       classnames={"mt-4"} />
      <div className="mt-4 w-full">
        <MainInput
          type={"text"}
          name={"asnwer"}
          value={currentAnswer}
          id="category-item-input"
          placeholder={t(`gameRoom.!isFinnishedGame.!isFinnishedRound.CurrentPlayer.ChooseRiddleStep.ChooseFromCategory.placeholder`) + " " + t(`categories.${categoryName}`)}
          onchange={(e) => {
            setCurrentAnswer(e.target.value);
            setAnswer(e.target.value)
            setShowOptions(true);
          }}
          isError={isError}
        />
      </div>

      {options.length > 0 && showOptions && (
        <div className="w-full max-h-[250px] overflow-auto mt-[-16px]">
          {loadingOptions && (
            <div className="w-full flex justify center">
              <Loader />
            </div>
          )}
          {options.map((option) => (
            <div
              key={option}
              className="w-full bg-light py-2 px-4 rounded rounded-[10px] my-1"
              onClick={() => {
                setCurrentAnswer(option);
                setAnswer(option);
                setShowOptions(false);
              }}
            >
              <h2 className="w-full">{option}</h2>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
