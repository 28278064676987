/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useData } from "../../../context/useData";

export const AuthContainer = ({ children }) => {
  const { user } = useData();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) navigate("/login");
  }, [user]);

  return <>{children}</>;
};
