import React, { useEffect } from "react";
import { langChars } from "../../globalConsts";

export const FontSize = () => {

  const lang =
    localStorage.getItem("i18nextLng").length > 2
      ? localStorage.getItem("i18nextLng").slice(0, 2)
      : localStorage.getItem("i18nextLng");

  const char = langChars[lang]
    ?.slice(0, 1)
    ?.toUpperCase();

  const sizes = [16, 20, 24];

  return (
    <div className="flex items-center justify-around mt-2">
      {sizes.map((size, index) => (
        <FontSizeButton key={index} char={char} size={size} />
      ))}
    </div>
  );
};

const FontSizeButton = ({ char, size }) => {
  const handleSetFontSize = () => {
    document.documentElement.style.setProperty("font-size", `${size}px`);
    localStorage.setItem("font-size", size.toString());
  };

  return (
    <>
      <button
        onClick={handleSetFontSize}
        className={`px-2 bg-light rounded flex items-center justify-center`}
        style={{
          fontSize: `${size}px`,
          width: `${size * 1.7}px`,
          height: `${size * 1.7}px`,
        }}
      >
        {char}
      </button>
    </>
  );
};
