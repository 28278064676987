/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { removeChar, revealChar, saveLevelState } from "../api/gameApi";
import { useData } from "../context/useData";
import { roundTimeInSeconds } from "../globalConsts";
import {
  addCharToPlace,
  CheckIfAnswerIsCorrect,
  createAnswerCharObject,
  createGuessCharObject,
  findGuessCharByIdAndReveal,
  getRandomItemFromArray,
  hasHiddenChar,
  hideCharInGuessArray,
  removeCharFromAnswer,
  removeNonIsRevealedChars,
  showCharInGuessArray,
  shuffleAndAddRandom,
} from "../services/riddleService";

export const useRiddle = (
  currentRiddle,
  setStep,
  finnishRound,
  timer,
  language,
  isSolo
) => {
  // Game States
  const [hiddenAnswer, setHiddenAnswer] = useState([]);
  const [charsToGuess, setCharsToGuess] = useState([]);
  // const [flattedAnswer, setFlattedAnswer] = useState("");
  const [loadingGame, setLoadingGame] = useState(false);
  const [secondsToHide, setSecondsToHide] = useState(null);
  const [loadingAction, setLoadingAction] = useState(false);
  const [endRoundTimer, setEndRoundTimer] = useState(false);
  const [nextLocation, setNextLocation] = useState([]);
  // const [error, setError] = useState('');

  const { setUser, user } = useData();
  const { solo: gameData } = user === "pending" ? { solo: null } : user?.game;
  const [isUserFetched, setIsUserFetched] = useState(false);

  // Fetched User verification
  useEffect(() => {
    if (user === "pending") {
    } else {
      setIsUserFetched(true);
    }
  }, [user]);

  // Initialize Game
  useEffect(() => {
    if (isUserFetched && currentRiddle) {
      // if have saved level state set him
      if (
        user?.game?.solo?.nonCompleteLevelsStates?.[currentRiddle._id]?.[
          language
        ]
      ) {
        const { hiddenAnswer: savedAnswer, charsToGuess: savedChars } =
          user?.game?.solo?.nonCompleteLevelsStates[currentRiddle._id][
            language
          ];
        setHiddenAnswer([...savedAnswer]);
        setCharsToGuess([...savedChars]);
      } else {
        handleAnswer(); // set new state
      }
    }

    return () => {
      setHiddenAnswer([]);
      setCharsToGuess([]);
    };
  }, [currentRiddle, isUserFetched]);

  useEffect(() => {
    // save the level states every change

    if (isSolo && charsToGuess?.length > 0) {
      (async () =>
        await saveLevelState(
          hiddenAnswer,
          charsToGuess,
          currentRiddle._id,
          user._id,
          language
        ))();
    }
  }, [charsToGuess]);

  //  check if the hidden answer is complete & correct and call handleFinnishRound if it is
  useEffect(() => {
    let timer = null;
    if (hiddenAnswer.length > 0 && !hasHiddenChar(hiddenAnswer)) {
      if (CheckIfAnswerIsCorrect(hiddenAnswer)) {
        setEndRoundTimer(true);

        timer = setTimeout(async () => {
          try {
            setEndRoundTimer(false);
            setLoadingGame(true);
            await handleFinnishRound();
          } catch (error) {
            console.error(error);
          } finally {
            setLoadingGame(false);
          }
        }, 3000);
      }
    }
    return () => clearTimeout(timer);
  }, [hiddenAnswer]);

  // Reveal chars until the time ends
  useEffect(() => {
    if ((timer || timer === 0) && secondsToHide) {
      if (secondsToHide.includes(timer)) {
        revealCorrectRandomCharInAnswer();
      }
    }
  }, [timer]);

  //  prepare the answer and the chars to guess
  const handleAnswer = () => {
    // answer words array
    const displayArray = [];
    // charsToGuess array
    const hiddenCharsArray = [];

    // generate the display array
    currentRiddle.answer[language]
      ?.toLowerCase()
      .split(" ")
      .forEach((word, wordIndex) => {
        // get index for initialy reveald chars
        const randomIndex = currentRiddle.shownCharsIndexes[language]
          ? currentRiddle.shownCharsIndexes[language][wordIndex]
          : Math.floor(Math.random() * word.length);

        const wordArray = [];

        word.split("").forEach((char, charIndex) => {
          if (randomIndex === charIndex)
            // initialy reveald char
            wordArray.push(createAnswerCharObject(char, true));
          else {
            // hidden chars
            wordArray.push(createAnswerCharObject(char, false));
            hiddenCharsArray.push(createGuessCharObject(char, false, true));
          }
        });
        displayArray.push(wordArray);
      });

    const shuffledCharsToGuess = shuffleAndAddRandom(
      hiddenCharsArray,
      language
    );
    setHiddenAnswer(displayArray);
    setCharsToGuess(shuffledCharsToGuess);

    if (timer) {
      setSecondsToHide(
        getArrayOfSecondsToHideChar(displayArray, roundTimeInSeconds)
      );
    }
  };

  //  handle finishing the round
  const handleFinnishRound = async () => {
    try {
      const score = timer * 10;
      await finnishRound(score);
      setStep(2);
    } catch (error) {
      console.log("Error in handleFinnishRound in useRiddle: ", error);
      throw new Error("Connection Problem");
    }
  };

  //  move char from the guessing chars array to hidden answer in first posiible place
  const handleMoveCharToAnswer = (char) => {
    // return if answer is full
    const nullValueAnswer = hiddenAnswer
      .flat(1)
      .filter((charObj) => charObj.currentValue === null);
    if (nullValueAnswer.length === 0) return;

    // Hide character in the guessing array
    const newCharsToGuess = hideCharInGuessArray(charsToGuess, char);
    setCharsToGuess([...newCharsToGuess]);

    // Add the current char to the answer in the first place
    const newHiddenAnswer = addCharToPlace(char, hiddenAnswer, nextLocation);
    setNextLocation([]);
    setHiddenAnswer([...newHiddenAnswer]);
  };

  //  handle removing characters from the answer
  const handleRemoveCharFromAnswer = (char) => {
    // Hide character in the answer and get original char from guessing array id
    const { newAnswer, originalCharId } = removeCharFromAnswer(
      hiddenAnswer,
      char
    );
    setHiddenAnswer([...newAnswer]);

    // Reveal back the original character
    const newCharsToGuess = findGuessCharByIdAndReveal(charsToGuess, [
      originalCharId,
    ]);
    setCharsToGuess([...newCharsToGuess]);
  };

  //  reset game
  const resetGame = () => {
    // remove non isRevealed chars from answer
    const { newAnswer, originalCharsIds } =
      removeNonIsRevealedChars(hiddenAnswer);
    setHiddenAnswer([...newAnswer]);

    // reveal back the original characters
    const newCharsToGuess = findGuessCharByIdAndReveal(
      charsToGuess,
      originalCharsIds
    );
    setCharsToGuess([...newCharsToGuess]);
  };

  // remove wrong char from guessing array
  const removeWrongCharFromCharsToGuess = async () => {
    if (
      isSolo &&
      (!gameData?.soloActions?.removeChars > 0 ||
        charsToGuess.filter((char) => !char.isHidden && !char.isAns).length ===
          0)
    )
      return;

    if (isSolo) {
      setLoadingAction(true);
      try {
        const { status, updatedUser } = await removeChar(user?._id);
        if (!status) return;
        else await setUser((prev) => ({ ...prev, ...updatedUser }));
      } catch (error) {
        return;
      }
      setLoadingAction(false);
    }

    // get array of ids of all wrong chars
    const wrongCharsIds = charsToGuess
      .map((char) => {
        if (!char.isAns && !char.isRemoved) return char.id;
        else return null;
      })
      .filter((char) => char !== null);

    // select random wrong char and remove permently from charsToGuess
    const randomCharId = getRandomItemFromArray(wrongCharsIds);

    // check if char is in answer
    let isInAnswer = false;

    const newHiddenAnswer = hiddenAnswer.map((word) => {
      return word.map((char) => {
        if (char.selectedCharId === randomCharId) {
          isInAnswer = true;

          // if true remove from answer
          return { ...char, currentValue: null, selectedCharId: "" };
        } else return char;
      });
    });

    if (isInAnswer) {
      setHiddenAnswer([...newHiddenAnswer]);
    }

    // update charsToGuess
    const newCharsToGuess = charsToGuess.map((char) => {
      if (char.id === randomCharId) {
        return { ...char, isRemoved: true, isHidden: true };
      } else return char;
    });

    setCharsToGuess([...newCharsToGuess]);
  };

  // reveal correct char in answer
  const revealCorrectRandomCharInAnswer = async () => {
    if (!gameData?.soloActions?.revealChars > 0) return;

    if (isSolo) {
      setLoadingAction(true);
      try {
        const { status, updatedUser } = await revealChar(user?._id);
        if (!status) return;
        else await setUser((prev) => ({ ...prev, ...updatedUser }));
      } catch (error) {
        return;
      }
      setLoadingAction(false);
    }

    let newCharsToGuess = [...charsToGuess];

    // get array of ids of all correct chars
    const correctCharsIds = charsToGuess
      .map((char) => {
        if (char.isAns && !char.isRemoved) return char.id;
        else return null;
      })
      .filter((char) => char !== null);

    // get random correct char and remove permently from charsToGuess
    const randomCharId = getRandomItemFromArray(correctCharsIds);

    // check if char is in answer
    let newHiddenAnswer = hiddenAnswer.map((word) => {
      return word.map((char) => {
        if (char.selectedCharId === randomCharId) {
          // if true remove from answer
          return { ...char, currentValue: null, selectedCharId: "" };
        } else return char;
      });
    });

    // reveal correct char
    const charById = charsToGuess.filter((char) => char.id === randomCharId)[0];
    let modified = false;

    // modify new hidden answer
    newHiddenAnswer = newHiddenAnswer.map((word) => {
      return word.map((char) => {
        if (
          !modified &&
          char.realValue === charById.value &&
          !char.isRevealed
        ) {
          modified = true;
          // if already has char return to chars to guess
          if (char.selectedCharId) {
            newCharsToGuess = showCharInGuessArray(newCharsToGuess, {
              id: char.selectedCharId,
            });
          }
          return {
            ...char,
            isRevealed: true,
            currentValue: char.realValue,
            selectedCharId: "",
          };
        } else return char;
      });
    });

    setHiddenAnswer([...newHiddenAnswer]);

    // remove from charsToGuess
    newCharsToGuess = newCharsToGuess.map((char) => {
      if (char.id === randomCharId) {
        return { ...char, isRemoved: true, isHidden: true };
      } else return char;
    });

    setCharsToGuess([...newCharsToGuess]);
  };

  // Get the array of the seconds when char needs to be hidden
  const getArrayOfSecondsToHideChar = (arrays, countdown) => {
    let seconds = [];
    let totalItems = arrays.reduce((acc, array) => acc + (array.length - 1), 0);
    let timePerItem = countdown / totalItems;

    arrays.flat(1).forEach((char, index) => {
      if (index < totalItems) seconds.push(parseInt(timePerItem * index));
    });

    return seconds;
  };

  return {
    loadingGame,
    loadingAction,
    endRoundTimer,
    hiddenAnswer,
    charsToGuess,
    handleMoveCharToAnswer,
    handleRemoveCharFromAnswer,
    resetGame,
    removeWrongCharFromCharsToGuess,
    revealCorrectRandomCharInAnswer,
    setNextLocation,
    nextLocation,
  };
};
