import React, { useEffect, useState } from "react";

import { useSocket } from "../../../context/useSocket";
import { WaitForAllPlayers } from "./WaitForAllPlayers";
import { ChooseRiddleStep } from "./ChooseRiddleStep";
import { WaitForPlayersFinnish } from "./WaitForPlayersFinnish";


export const CurrentPlayer = () => {
  const [step, setStep] = useState(0);
  const { socketUsers, startRound, currentRoom } = useSocket();

  useEffect(() => {
    if (socketUsers.length < 2) setStep(0)
  }, [socketUsers])
  


  return <>
    {step === 0 && <WaitForAllPlayers setStep={setStep} socketUsers={socketUsers} isFirstRound={currentRoom?.rounds === currentRoom.initialRounds} />}
    {step === 1 && <ChooseRiddleStep setAnswer={startRound} setStep={setStep} category={currentRoom?.category}  />}
    {step === 2 && <WaitForPlayersFinnish socketUsers={socketUsers} />}
    </>;
};





