import React from 'react'
import { useSocket } from '../../../context/useSocket'
import { UserCard } from '../user/UserCard'

export const UsersBar = () => {
    const { socketUsers } = useSocket();
    
  return (
    <div className="flex items-center py-4 px-2 mt-4 bg-white/70 rounded-2xl overflow-x-auto">
      {socketUsers.map((user, idx) => (
        <UserCard user={user} index={idx} key={user._id} />
      ))}
      </div>

  )
}
