import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { resetPassword } from "../../../api/userApi";
import { MainButton } from "../../../components/button/MainButton";
import { Container } from "../../../components/container/Container";
import { ErrorMessage } from "../../../components/errors/ErrorMessage";
import { MainInput } from "../../../components/input/MainInput";
import { Loader } from "../../../components/loader/Loader";
import { Paragraph } from "../../../components/text/Paragraph";
import { Title } from "../../../components/text/Title";
import { urls } from "../../../globalConsts";

export const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [confirmed, setConfirmed] = useState(false);

  const { t } = useTranslation();
  const { key } = useParams();
  const { isLoading, isError, error, refetch } = useQuery(
    "reset-password",
    async () => await resetPassword(newPassword, key),
    {
      enabled: false,
      onSuccess: (data) => {
        setConfirmed(true);
      },
    }
  );

  const validatePasswords = () => {
    if (newPassword.length < 6) return true;
    else if (newPassword !== confirmNewPassword) return true;
    else return false;
  };

  return (
    <Container page>
      <Container>
        {confirmed ? (
          <>
            <Title title={t("reset-password.all-done")} />
            <Paragraph
              paragraph={t("reset-password.login-with-new-password")}
              classnames={"text-center"}
            />
            <MainButton
              link={urls.login}
              label={t("common.login")}
              classnames={"mt-8"}
            />
          </>
        ) : (
          <>
            <Title title={t("reset-password.reset-your-password")} />
            <Paragraph
              paragraph={t("reset-password.here-reset")}
              classnames={"text-center"}
            />

            <div className="mt-6">
              {isError && (
                <ErrorMessage
                  text={
                    error?.response?.data?.message
                      ? error?.response?.data?.message
                      : t('errors.somthing-went-wrong')
                  }
                />
              )}

              <MainInput
                label={t('reset-password.new-password')}
                type={"password"}
                name={"newPassword"}
                value={newPassword}
                id="newPassword"
                placeholder={t('reset-password.new-password')}
                onchange={(e) => setNewPassword(e.target.value)}
                isError={isError}
              />

              <MainInput
                label={t('reset-password.confirm-new-password')}
                type={"password"}
                name={"confirmNewPassword"}
                value={confirmNewPassword}
                id="confirmNewPassword"
                placeholder={t('reset-password.confirm-new-password')}
                onchange={(e) => setConfirmNewPassword(e.target.value)}
                isError={isError}
              />

              {isLoading ? (
                <div className="w-full flex justify-center">
                  {" "}
                  <Loader />
                </div>
              ) : (
                <MainButton
                  disabled={validatePasswords()}
                  onclick={refetch}
                  label={t('reset-password.change')}
                />
              )}
            </div>
          </>
        )}
      </Container>
    </Container>
  );
};
