import classNames from 'classnames'
import React from 'react'


export const MainInput = ({ label, type, name, value, id, placeholder, onchange, isError, maxLength }) => {

    const inputClassnames = `form-control block w-full px-4 py-4 text-xl font-md text-gray-700 bg-white bg-clip-padding border-solid border-2 ${isError ? 'border-secondery-color' : 'border-primary-color'} rounded-2xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-dark focus:outline-none`
  return (
    <div className="mb-6">
    <h2 className={`${isError ? 'text-secondery-color' : 'text-dark'} mb-2`}>{label}</h2>
    {type === "textarea" ? <textarea
      name={name}
      value={value}
      className={classNames(inputClassnames, "min-h-[150px] max-h-[250px]") }
      id={id}
      placeholder={placeholder}
      onChange={onchange}
      maxLength={maxLength}
    /> 
    :
    <input
      type={type}
      name={name}
      value={value}
      className={inputClassnames}
      id={id}
      placeholder={placeholder}
      onChange={onchange}
      maxLength={maxLength}
    />
    }
  </div>
  )
}
