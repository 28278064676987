import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/logos/logo-simple.png";
import logoMin from "../../assets/logos/logo-simple-min.png";
import { APP_NAME } from "../../globalConsts";
import { ReactComponent as MenuIcon } from "../../assets/svg-icons/menu.svg";
import { HeaderMenu } from "./HeaderMenu";
import { useTheme } from "../../context/useTheme";
import { useAppSound } from "../../context/useSound";
import useSound from "use-sound";
import buttonSound from "../../assets/sounds/main-click.mp3";

export const Header = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  // const { user } = useData();
  const { isRtl } = useTheme();
  const { t } = useTranslation();

  const { volume, isSfx } = useAppSound();

  const [play, { sound }] = useSound(buttonSound, {
    playbackRate: 0.75,
    interrupt: true,
  });

  useEffect(() => {
    if (sound) {
      sound.volume(isSfx ? volume : 0);
    }
  }, [volume, isSfx, sound]);

  const handleImageLoad = () => {
    setIsImageLoading(false);
  };

  return (
    <>
      <header
        className={`w-full h-[60px] flex items-center justify-between py-4 px-2 shadow-md ${
          menuIsOpen ? "bg-white" : "bg-white/40"
        } backdrop-blur-sm  z-40`}
      >
        <Link to="/" className="flex items-center" onClick={play}>
          {isImageLoading && (
            <img
              src={logoMin}
              className=" h-6 sm:h-9"
            />
          )}
          <img
            src={logo}
            onLoad={handleImageLoad}
            style={{ display: isImageLoading ? "none" : "" }}
            className=" h-6 sm:h-9"
            alt="Emoji"
          />
          <span className="self-center text-dark text-xl whitespace-nowrap mx-2">
            {t("app.name")}
          </span>
        </Link>
        {/* <Link to="/settings" className="text-dark text-xl flex items-center">

          <MenuIcon />
        </Link> */}
        <button
          onClick={() => {
            if (isSfx) play();
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          <MenuIcon />
        </button>
      </header>
      <HeaderMenu
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
        isRtl={isRtl}
      />
    </>
  );
};
