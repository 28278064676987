import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAvatar } from "../../api/userApi";
import { Avatar } from "../../components/avatar/Avatar";
import { LeaveButton } from "../../components/button/LeaveButton";
import { MainButton } from "../../components/button/MainButton";
import { Container } from "../../components/container/Container";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import { MainInput } from "../../components/input/MainInput";
import { Title } from "../../components/text/Title";
import { useData } from "../../context/useData";
import { useSocket } from "../../context/useSocket";
import { initialAvatar } from "../../globalConsts";
import { generateRandomString } from "../../services/dataManipulation";

export const EnterUser = () => {
  const { user } = useData();
  const [userName, setUserName] = useState("");
  const [userAvatar, setUserAvatar] = useState(initialAvatar)
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { createStockUser, socketUser } = useSocket();

  const handleSetUser = async () => {
    try {
      await createStockUser(userName, userAvatar);
    } catch (err) {
      setError("Somthing Went Wrong");
    }
  };

  const handleAvatarChange = async () => {
    try {
      const randomName = generateRandomString();
      const data = await getAvatar(randomName);
      const url = `data:image/svg+xml,${encodeURIComponent(data)}`
      setUserAvatar(url)
    } catch (error) {
      setError('Error With Avatar Selection')
    }
  }

  useEffect(() => {
    if (socketUser || user) navigate("/lobby");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketUser]);

  return (
    <Container page>
      <Container>
        <LeaveButton toUrl={"/"} />
        <Title title={"Create User"} />
        {error && <ErrorMessage text={error} />}
        <div className="w-full my-6 flex flex-col items-center">
          <Avatar imageUrl={userAvatar} size={"xl"} classnames={"border-4"} />
          <button
            onClick={handleAvatarChange}
            className="mt-4 text-dark underline"
          >
            Randomize
          </button>
        </div>
        <div className="mt-4">
          <MainInput
            label={"Enter Nickname"}
            type={"text"}
            name={"nickname"}
            value={userName}
            id="nickname"
            placeholder="Nickname"
            onchange={(e) => setUserName(e.target.value)}
            isError={error}
          />
        </div>
        <MainButton
          onclick={async () => {
            await handleSetUser();
          }}
          disabled={userName.length < 2}
          label={"NEXT"}
        />
      </Container>
    </Container>
  );
};
