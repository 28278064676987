import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MainButton } from '../../components/button/MainButton'
import { Container } from '../../components/container/Container'
import { Paragraph } from '../../components/text/Paragraph'
import { Title } from '../../components/text/Title'
import { useSocket } from '../../context/useSocket'
import { urls } from '../../globalConsts'

export const NoSocketConnection = () => {
    const { error } = useSocket();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
    if (!error) navigate('/')  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
  return (
    <Container page>
        <Container>
            <Title title={t('errors.network')}/>
            <Paragraph paragraph={t('errors.connection')} classnames={"!text-center"}/>
            <Paragraph paragraph={t('errors.try-later')}/>
            <MainButton link={urls.home} label={t('screens.home')}/>
        </Container>
    </Container>
  )
}
