import React, { useCallback } from "react";
import classNames from "classnames";
import { initialAvatar } from "../../globalConsts";

const avatarClassname =
  "rounded-full border-solid border-2 border-primary-color";

export const Avatar = ({ imageUrl, size = "lg", classnames }) => {
  const getSize = useCallback(() => {
    if (size === "xs") return "w-[24px] h-[24px]";
    else if (size === "sm") return "w-[32px] h-[32px]";
    else if (size === "md") return "w-[64px] h-[64px]";
    else if (size === "lg") return "w-[86px] h-[86px]";
    else if (size === "xl") return "w-[128px] h-[128px]";
  }, [size]);

  return (
    <img
      src={imageUrl ? imageUrl : initialAvatar}
      alt="Avatar"
      className={classNames(avatarClassname, getSize(), classnames)}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src=initialAvatar;
      }}
    />
  );
};
