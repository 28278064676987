// export const SOCKET_SERVER_URL = 'https://socket-server-test.up.railway.app';
// export const SOCKET_SERVER_URL = 'http://localhost:5005';
// export const SOCKET_SERVER_URL = 'http://192.168.68.103:5005';
// export const SERVER_URL = 'https://socket-server-test.up.railway.app';
// export const SERVER_URL = 'http://localhost:5005';
// export const SERVER_URL = 'http://192.168.68.103:5005';

export const SOCKET_SERVER_URL = process.env.REACT_APP_SERVER;
export const SERVER_URL = process.env.REACT_APP_SERVER;

