import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const GameSelectCard = ({ option, isCurrent, onclick, classnames }) => {
    const gameSelectCardClassnames = `${isCurrent ? 'bg-primary-color' : 'bg-light'} border-4 border-primary-color rounded-lg flex flex-col items-center justify-center max-h-[160px] min-h-[100px] ${option.isAvailable ? '' : 'opacity-70'} transition-all ease duration-300`
    const { t } = useTranslation()
  return (
    <button className={classNames(gameSelectCardClassnames, classnames)} disabled={!option.isAvailable} onClick={onclick} >
        {option.icon && option.icon}
        <h3 className={`text-2xl ${isCurrent ? 'text-light' : ''}`}>{t(`game-options.${option.value}`)}</h3>
        {!option.isAvailable && <p className={` ${option.isAvailable ? 'opacity-0' : ''}`}>{t('common.comming-soon')}</p>}
    </button>
  )
}
