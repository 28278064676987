import React from "react";
import classNames from "classnames";
import bg from "../../assets/backgrounds/main-bg.png";

// const bgClassName = "bg-gradient-to-t from-[#2980B9] via-[#6DD5FA] to-[#FFFFFF] h-screen w-screen p-8";
// const bgClassName = "bg-gradient-to-t from-[#3a7bd5]  to-[#00d2ff] h-screen w-screen overflow-x-hidden overflow-auto relative";

export const BackGround = ({ children, classnames, isRtl }) => {

  const bgClassName =
    `bg-[#65C7D6]  w-[100%] h-[100%]  mx-auto overflow-x-hidden relative flex flex-col ${isRtl ? 'dir-rtl' : ''}`;


  return (
    <div className={classNames(bgClassName, classnames)}>
      <div className="z-10 relative  flex flex-1 h-[100%] overflow-hidden ">
        {children}
      </div>

      <div
        className="w-[100%] h-[100%] opacity-40 bg-blend-overlay bg-[#65C7D6] absolute top-0 left-0 z-0"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  );
};
