/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { roundTimeInSeconds } from "../../../globalConsts";
import { useInterval } from "../../../hooks/useInterval";
import { ProgressBar } from "../../progress-bar/ProgressBar";
import { Title } from "../../text/Title";

export const WaitForPlayersFinnish = () => {
    const { timer, percentage, setIsRunning } = useInterval(roundTimeInSeconds);

    // initialize timer
    useEffect(() => {
      setIsRunning(true)
    }, [])
    
  return (
    <div className="w-full">

      <Title title={"Wait For All Players To Finnish"} />

      {/* <UsersBar /> */}

      <div className="mt-4">
      <h1 className="w-full text-center text-2xl mb-2">Time Left: {timer}</h1>
      <ProgressBar percentage={percentage} />
      </div>

    </div>
  );
};
