import React from 'react'
import { useTranslation } from 'react-i18next'
import { LeaveButton } from '../../components/button/LeaveButton'
import { MainButton } from '../../components/button/MainButton'
import { Container } from '../../components/container/Container'
import { Paragraph } from '../../components/text/Paragraph'
import { Title } from '../../components/text/Title'
import { soloGameTypes, urls } from '../../globalConsts'

export const TypeChoose = () => {
  const { t } = useTranslation();

  return (
    <Container page>
      <LeaveButton toUrl={`/choose-game`}/>
        <Container>
        <Title title={t('solo-choose.choose-subject')} />
        <Paragraph
          paragraph={t('solo-choose.choose-subject-play')}
        />
      </Container>
      <div className='mt-4 flex-1'>
        {soloGameTypes.map((gameType, index) => <MainButton key={index} label={t(`solo-choose.${gameType.name}`)} link={`${urls.solo}/${gameType.name}/level-choose`}/>)}
        {/* <MainButton label={t('solo-choose.movies')} link={`${urls.solo}/movies/level-choose`}/>
        <MainButton label={t('solo-choose.tv-shows')} link={`${urls.solo}/tv-shows/level-choose`} disabled={true}/>
        <MainButton label={t('solo-choose.music')} link={`${urls.solo}/music/level-choose`} disabled={true}/> */}
      </div>
    </Container >
  )
}
