import React from 'react'
import classNames from 'classnames';

const titleClassnames = 'text-4xl font-bold text-gray-800 leading-tight block text-center  '

export const Title = ({ title, classnames }) => {
  return (
    <h1 className={classNames(titleClassnames, classnames)}>{title}</h1>
  )
}
