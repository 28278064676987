import axios from "axios";
import { SERVER_URL } from "../config";
import { transformGoogleUser } from "../services/dataManipulation";

export const getApiStatus = async () => {
  const { data } = await axios.get(`${SERVER_URL}/api/status`);
  console.log(data);
};

export const registerUser = async (registerForm) => {
  const data = await axios.post(
    `${SERVER_URL}/api/auth/register`,
    registerForm
  );
  return data;
};

export const validateNickname = async (nickName) => {
  const { data } = await axios.post(`${SERVER_URL}/api/auth/validateNickname`, {
    nickName,
  });
  return data;
};

export const validateEmail = async (email) => {
  const { data } = await axios.post(`${SERVER_URL}/api/auth/validateEmail`, {
    email,
  });
  return data;
};

export const loginUser = async (loginForm) => {
  const data = await axios.post(`${SERVER_URL}/api/auth/login`, loginForm);
  return data;
};

export const handleLoginGoogleUser = async (token) => {
  const { data, status } = await axios.get(
    "https://people.googleapis.com/v1/people/me",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        personFields: "names,photos,emailAddresses",
      },
    }
  );

  if (status === 200) {
    try {
      const googleUser = transformGoogleUser(data);
      const { data: googleUserData } = await axios.post(
        `${SERVER_URL}/api/auth/google-login`,
        { email: googleUser.email }
      );
      return { ...googleUser, ...googleUserData?.user };
      // return { _id: googleUserData?.user._id, levelsCompletedIds: googleUserData?.user?.levelsCompletedIds , ...googleUser }
    } catch (err) {
      throw new Error("Network Error");
    }
  }
};

export const fetchUserByToken = async (token) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}/api/auth/verifyToken`, {
      token,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchUser = async (userId) => {
  const { data } = await axios.post(`${SERVER_URL}/api/auth/getUser`, {
    userId,
  });
  return data;
};

export const confirmEmail = async (key) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}/api/auth/confirm`, {
      key,
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const forgotPassword = async (email) => {
  const data = await axios.post(`${SERVER_URL}/api/auth/forgot-password`, {
    email,
  });
  return data;
};

export const resetPassword = async (newPassword, key) => {
  const data = await axios.post(`${SERVER_URL}/api/auth/reset-password`, {
    newPassword,
    key,
  });
  return data;
};

export const updateUserProfile = async (profileForm, userId) => {
  const data = await axios.post(`${SERVER_URL}/api/auth/update-profile`, {
    userId,
    profileForm,
  });
  return data;
};

export const sendFeedback = async (feedbackForm, userId) => {
  const data = await axios.post(`${SERVER_URL}/api/auth/send-feedback`, {
    userId,
    feedbackForm,
  });
  return data;
};

export const getAvatar = async (avatarName) => {
  const { data } = await axios.get(
    `https://api.multiavatar.com/${avatarName}.svg`
  );
  return data;
};



