/* eslint-disable react-hooks/exhaustive-deps */
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useData } from "./context/useData";
import { routes, rtlLanguages, tvShows } from "./globalConsts";

import { BackGround } from "./components/background/BackGround";
import { Loading } from "./pages/loading/Loading";
import { useEffect } from "react";
import { getApiStatus } from "./api/userApi";
import { useTranslation } from "react-i18next";
import { useTheme } from "./context/useTheme";
import { BackgroundMusic } from "./components/music/BackgroundMusic";

const router = createBrowserRouter(routes);

function App() {
  const { loadingUser, pageLoading } = useData();
  const { isRtl ,setIsRtl } = useTheme();
  const { i18n } = useTranslation();

  useEffect(() => {

    // check server status
    (async () => {
      // await getApiStatus();
    })();

  }, []);
  
  useEffect(() => {
    // handle direction for current language
   if (rtlLanguages.includes(i18n.resolvedLanguage)) {
    setIsRtl(true)
   } else setIsRtl(false);
  //  i18n.changeLanguage('en')

  }, [i18n.language])


  return (
    <>
      {(loadingUser || pageLoading )
        && (
          <BackGround classnames={"w-full h-full fixed top-0 left-0"}>
            <Loading />
          </BackGround>
        )}

      <BackGround isRtl={isRtl} >
        <RouterProvider router={router} />
      </BackGround>

      <BackgroundMusic />
    </>
  );
}

export default App;
