import React from 'react'
import { useTranslation } from 'react-i18next';

import {ReactComponent as Logo} from '../../assets/logos/logo.svg';
import { Loader } from '../../components/loader/Loader';


export const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col items-center z-50 pt-[38px] w-full'>
        <div className='mt-6 flex items-cneter justify-center'>
        <Logo className='m-0 ml-4 w-[80%]'/>
        </div>
        <h1 className='text-5xl mt-14 text-white'>{t('common.loading')}</h1>
        <Loader />
    </div>
  )
}
