import React, {  useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { completeLevel, getLevels } from "../../api/gameApi";
import { LeaveButton } from "../../components/button/LeaveButton";
import { Container } from "../../components/container/Container";
import GuessRiddle from "../../components/game-components/guesser/guess-riddle/GuessRiddle";
// import { GuessTheRiddle } from "../../components/game-components/guesser/GuessTheRiddle/GuessTheRiddle";
import { Loader } from "../../components/loader/Loader";
import { useData } from "../../context/useData";
import data from "../../constants/data/levels-full.json";
import { coinsPerLevel } from "../../globalConsts";

import { ReactComponent as CoinIcon } from "../../assets/svg-icons/coin.svg";


export const SoloRiddle = () => {
  const { type, level, index } = useParams();
  const navigate = useNavigate();
  const [, setErrorMessage] = useState("");

  const { t } = useTranslation()
  const { user, setUser } = useData();

  const levels = data["levels"].filter(currentLevel => currentLevel.category === type && currentLevel.level == level);

  // const { isLoading: loadingLevels, data: levels, isError: levelsError } = useQuery([`get-${type}-levels`, index],() => getLevels(type, level), {
  //   onSuccess: (data) => {
  //     // setLevels(data)
  //     if (data.length < 1) navigate(`/solo/${type}/level-choose`)
  //   },
  // });


  // const riddlesData = dataMock[type];
  const currentRiddle = levels?.length > 0 ? levels[index] : null;
  // const currentRiddle = dataMock[type][index];
  

  const { isLoading, refetch } = useQuery(
    "complete-level",
    () => completeLevel(user._id, currentRiddle._id, coinsPerLevel, type, level),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: 1 * 60 * 60 * 1000,
      staleTime: 1 * 60 * 60 * 1000,
      onSuccess: (data) => {
        // update user after level completed
        setUser(prev => ({ ...prev, ...data }))
      },
      onError: (err) => {
        setErrorMessage(err.message);
      },
    }
  );

  const handleFinnishLevel = async () => {
    await refetch();

  };

  const handleNextLevel = () => {
    index === '9' ? navigate(`/solo/${type}/${+level + 1}/1`) : navigate(`/solo/${type}/${level}/${+index + 1}`)
  }


  if (!currentRiddle) navigate(`/solo/${type}/level-choose`)
  if (user?.game?.solo?.levelsCompletedIds?.includes(currentRiddle?._id)) navigate(`/solo/${type}/level-choose`)

  return (
    <Container page noHeader >
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="h-full w-full flex flex-col">
        <div className="w-full flex justify-between items-center">
          <LeaveButton toUrl={`/solo/${type}/${level}`}/>
          <div className="flex items-center mb-2 rounded-lg bg-light/80 px-2"><CoinIcon className="w-[20px]" /><span className="mx-2">{user?.game?.solo?.soloActions?.coins}</span></div>
        </div>
          {currentRiddle && (
            <GuessRiddle
              currentRiddle={currentRiddle}
              setStep={handleNextLevel}
              finnishRound={handleFinnishLevel}
              leaveUrl={`/solo/${type}/${level}`}
              isSolo={true}
              actions={user?.game?.solo?.soloActions}
            />
          )}
        </div>
      )}
    </Container>
  );
};
