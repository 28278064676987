/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../context/useSocket';

export const SocketContainer = ({ children }) => {
    const { isConnected, error } = useSocket();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isConnected || error) navigate('/connection-error')
    }, [isConnected])
    

    
  return (
    <>{children}</>
  )
}