/* eslint-disable react-hooks/exhaustive-deps */

import { SOCKET_SERVER_URL } from "../config";
import shortid from "shortid";

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import io from "socket.io-client";
import {
  checkIfRoomExists,
  saveSocketUser,
  updateSocketUser,
} from "../api/socketApi";
import { initialSocketUser } from "../globalConsts";

const initialRiddle = {
  "category": "movies",
  answer: "",
  emojis: "",
};

const initialCurrentRoom = {
  roomId: '',
  userIds: [],
  creatorId: '',
  rounds: 0,
  category: ''
}

const SocketContext = createContext();

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const [socketUser, setSocketUser] = useState(null);
  const [socketUsers, setSocketUsers] = useState([]);
  const [currentRoomId, setCurrentRoomId] = useState("");
  const [currentRiddle, setCurrentRiddle] = useState(initialRiddle);
  const [currentRoom, setCurrentRoom] = useState(initialCurrentRoom);
  // const [chat, setChat] = useState([]);

  // useEffect(() => {
  //   // let timeout;
  //   // Create a new socket instance
  //   const socket = io(SOCKET_SERVER_URL, {
  //     transports: ["websocket", "polling", "flashsocket"],
  //   });

  //   // Connect to the socket
  //   // socket.connect();

  //   // Set isConnected to true when the socket connects
  //   socket.on("connect", () => {
  //     setIsConnected(true);
  //     let socketUserId = localStorage.getItem("socketUserId");
  //     if (socketUserId) {
  //       socket.emit("reconnect", { socketUserId })
  //     } 
  //   });

  //   // Set isConnected to false when the socket disconnects
  //   socket.on("disconnect", () => {
  //     setIsConnected(false);
      
  //   });


  //   socket.on('reconnected', ({ socketUser: prevSocketUser }) => {
  //       if (!socketUser) {
  //         setSocketUser(prevSocketUser)
  //         setSocketUsers([])
  //       }
  //   });

  //   // Handle connection errors
  //   socket.on("connect_error", (error) => {
  //     setError(error);
  //   });

  //   socket.on("connect_timeout", (error) => {
  //     setError(error);
  //   });

  //   socket.on('error', (error) => {
  //     setError(error)
  //   })

  //   // Set the socket in the state
  //   setSocket(socket);

  //   // Disconnect the socket and reset the state when the component unmounts
  //   return () => {
  //     socket.disconnect();
  //     setSocket(null);
  //     setIsConnected(false);
  //     setError(null);
  //     setSocketUser(null);
  //   };
  // }, []); // The dependencies array is empty to ensure the effect only runs once on mount

  // Reconnect the socket when the user clicks the "Reconnect" button
  const handleReconnectClick = () => {
    socket.disconnect();
    socket.connect();
  };

  

  // create new socket user
  const createStockUser = async (name, avatar) => {
    const newSocketUser = {
      ...initialSocketUser,
      name,
      avatar
    };
    const savedSocketUser = await saveSocketUser(newSocketUser);
    socket.emit('save-user-id', savedSocketUser._id)
    localStorage.setItem("socketUserId", savedSocketUser._id);
    setSocketUser(savedSocketUser);
  };

  // join room
  const handleJoinRoom = useCallback(
    async (roomId, roomOptions) => {
      let updatedSocketUser;
      // join existing room
      if (roomId) {
        const isRoomExists = await checkIfRoomExists(roomId)
        if (isRoomExists) {

          updatedSocketUser = { ...socketUser, currentRoomId: roomId };
          setCurrentRoomId(roomId);
          socket.emit("join-room", { roomId, socketUser: updatedSocketUser });
          await updateSocketUser(updatedSocketUser);
          return true;
        } else { 
          setError({ message: 'Room does not exists'})
          return false
        }
      } else {
        // create new room
        const newRoomId = shortid.generate().slice(0, 4);
        setCurrentRoomId(newRoomId);
        updatedSocketUser = {
          ...socketUser,
          currentRoomId: newRoomId,
          isPlaying: true,
          isCreator: true,
        };

        socket.emit("join-room", {
          roomId: newRoomId,
          socketUser: updatedSocketUser,
          roomOptions: roomOptions,
        });
        await updateSocketUser(updatedSocketUser);
        return newRoomId;
      }
    },
    [socketUser]
  );

  // leave room
  const handleLeaveRoom = useCallback(() => {
    if (!socket) return true;
    socket.emit("leave-room", {
      socketUserId: socketUser._id,
      roomId: currentRoomId,
    });
    setSocketUser((prev) => ({
      ...prev,
      currentRoomId: "",
      score: 0,
      isFinnishedRound: false,
      isPlaying: false,
      isCreator: false,
    }));
    setSocketUsers([]);
    setCurrentRoomId("");
  }, [socketUser, currentRoomId]);

  // subscribe to room users updates
  const subscribeToUsersUpdate = (cb) => {
    if (!socket) return true;
    socket.on("users-updated", ({ roomUsers, isCreator, creator, isGameOver }) => {
      // if (roomIsDeleted && isCreator) setSocketUser({ ...creator });
      // if (updatedRoom) setCurrentRoom(updatedRoom);
      return cb(null, roomUsers, isCreator, isGameOver);
    });
  };

  // subscribe to room updates
  const subscribeToRoomUpdate = (cb) => {
    if (!socket) return true;
    socket.on("room-updated", ({ updatedRoom , roomIsDeleted, }) => {
      if (roomIsDeleted ) setCurrentRoom(null);
      if (updatedRoom) setCurrentRoom(updatedRoom);
      // return cb(null, roomUsers, isCreator, roomIsDeleted, isGameOver);
    });
  };


  // subscribe to game event udapte
  const subscribeToGameUpdate = (cb) => {
    if (!socket) return true;
    socket.on("game-updated", (currentRiddle) => {
      // return cb(null, currentRiddle);
      setCurrentRiddle(currentRiddle);
    });
  };

  // subscribe to chat updaate
  const subscribeToChatUpdate = (cb) => {
    if (!socket) return true;
    socket.on("chat-updated", (message) => {
      // return cb(null, currentRiddle);
      // setChat((prev) => [...prev, message]);
    });
  };

  // set riddle
  const startRound = (currentRiddle) => {
    socket.emit("start-round", {
      currentRiddle,
      roomId: currentRoomId,
      currentSocketUserId: socketUser?._id,
    });
  };


  const endRound = (points) => {
    socket.emit("end-round", {
      points,
      roomId: currentRoomId,
      socketUserId: socketUser?._id,
    });
  };

  const handleNextPlayer = () => {
    socket.emit("next-player", {
      roomId: currentRoomId,
      isCurrentPlayer: socketUser.isPlaying,
      currentPlayerId: socketUser._id,
    });
  };

  const value = {
    socket,
    isConnected,
    error,
    setError,
    handleReconnectClick,
    createStockUser,
    handleJoinRoom,
    socketUser,
    setSocketUser,
    socketUsers,
    setSocketUsers,
    subscribeToUsersUpdate,
    subscribeToRoomUpdate,
    subscribeToGameUpdate,
    subscribeToChatUpdate,
    handleLeaveRoom,
    currentRiddle,
    setCurrentRiddle,
    startRound,
    endRound,
    handleNextPlayer,
    currentRoom
  };

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};





























