import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeSocketUser } from "../../api/socketApi";
import { MainButton } from "../../components/button/MainButton";
import { Container } from "../../components/container/Container";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import { MainInput } from "../../components/input/MainInput";
import { Title } from "../../components/text/Title";
import { useData } from "../../context/useData";
import { useSocket } from "../../context/useSocket";
import { LeaveButton } from "../../components/button/LeaveButton";
import { useTranslation } from "react-i18next";

export const Lobby = () => {
  const [roomId, setRoomId] = useState("");
  const [loading, setLoading] = useState({ type: "", isLoading: false });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    handleJoinRoom,
    socketUser,
    setSocketUser,
    error,
    setError,
    createStockUser,
  } = useSocket();
  const { user, loadingUser } = useData();

  useEffect(() => {
    if (!socketUser && !user && !loadingUser) navigate("/enter-user");
    else if (!socketUser && user) {
      (async () => {
        try {
          await createStockUser(user?.nickName, user?.avatar);
        } catch (error) {
          console.log("Error in lobby: ", error)
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketUser, loadingUser]);

  const handleGameRoom = async (isJoining) => {
    setError(null);
    if (isJoining) {
      setLoading({ type: "join", isLoading: true });
      try {
        const isOk = await handleJoinRoom(roomId);
        if (isOk) {
          navigate(`/game/${roomId}`);
        }
        setLoading({ type: "join", isLoading: false });
      } catch (error) {
        console.error(error)
      }
    } else {
      // setLoading({type: 'create', isLoading: true})
      // const newRoomId = await handleJoinRoom(null);
      // setLoading({type: 'create', isLoading: false})
      navigate(`/game-options`);
    }
  };

  const handleLeaveLobby = async () => {
    // remove socket user on leave

    const { status } = await removeSocketUser(socketUser?._id);
    if (status === "REMOVED") {
      setSocketUser(null);
      user ? navigate("/") : navigate("/enter-user");
    }
  };

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError(null);
      }, 6000);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // if (!socketUser) return <ScreenLoader />

  return (
    <Container page>
      <div className="w-[100%]">
        <LeaveButton
          toUrl={`/choose-game`}
          onclick={async () => await handleLeaveLobby()}
        />
      </div>

      <div
        className={`bg-light/70 rounded-xl my-2 transition-all ease-out ${
          error ? "max-h-[200px] px-2 py-1" : "max-h-0"
        }`}
      >
        <ErrorMessage text={error?.message} />
      </div>

      <Container>
        <Title title={t("lobby.title")} />
        <MainButton
          onclick={() => handleGameRoom(false)}
          label={
            loading.type === "create" && loading.isLoading
              ? t("lobby.creatingRoom")
              : t("lobby.createRoom")
          }
        />
        {/* <button onClick={() => handleGameRoom(false)}>{(loading.type === 'create' && loading.isLoading) ? 'Creating' :'Create Room'}</button> */}
        <div className="flex items-center justify-center">
          <div className="w-1/3 border-b border-dark/30"></div>
          <h2 className="text-xl font-bold mt-6 mb-6 px-4 text-dark">
            {t("lobby.or")}
          </h2>
          <div className="w-1/3 border-b border-dark/30"></div>
        </div>
        <MainInput
          label={t("lobby.roomId")}
          type={"text"}
          name={"room-id"}
          value={roomId}
          id="room-id"
          placeholder={t("lobby.roomId")}
          onchange={(e) => setRoomId(e.target.value)}
          isError={error}
          maxLength={4}
        />

        <MainButton
          onclick={() => handleGameRoom(true)}
          label={
            loading.type === "join" && loading.isLoading
              ? t("lobby.joiningRoom")
              : t("lobby.joinRoom")
          }
          disabled={roomId.length < 4}
        />
      </Container>
      {/* <button onClick={() => handleGameRoom(true)}>{(loading.type === 'join' && loading.isLoading) ? 'Joinning' :'Join Room'}</button> */}
    </Container>
  );
};
