import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeaveButton } from "../../components/button/LeaveButton";
import { MainButton } from "../../components/button/MainButton";
import { Container } from "../../components/container/Container";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import {
  GameSelectCard,
} from "../../components/game-components/game-select/GameSelectCard";
import { GameSelectContainer } from "../../components/game-components/game-select/GameSelectContainer";
import { Paragraph } from "../../components/text/Paragraph";
import { Subtitle } from "../../components/text/Subtitle";
import { Title } from "../../components/text/Title";
import { useSocket } from "../../context/useSocket";
import { gameCategories, gameNumberOfRoundOptions } from "../../globalConsts";
import { useTranslation } from "react-i18next";

export const ChooseGroupGameOptions = () => {
  const [currentCategory, setCurrentCategory] = useState("movies");
  const [numberOfRounds, setNumberOfRounds] = useState(1);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  const navigate = useNavigate();
  const { handleJoinRoom, error, setError } = useSocket();

  const handleOpenRoom = async () => {
    setLoading(true);
    const newRoomId = await handleJoinRoom(null, {category: currentCategory, rounds: numberOfRounds, initialRounds: numberOfRounds});
    setLoading(false);
    if (newRoomId) navigate(`/game/${newRoomId}`);
    else setError('Connection Problem')
  };

  return (
    <>
      <Container page noHeader>
        <LeaveButton toUrl={'/lobby'} classnames={'text-left mb-2'}/>
      <Container>
        <Title title={t('game-options.title')} />
        <Paragraph
          paragraph={t('game-options.body')}
        />
      </Container>

      {error && <ErrorMessage error={error}/>}

      <Subtitle subtitle={t('game-options.category')} />

      <GameSelectContainer>
        {gameCategories.map((category, index) => (
          <GameSelectCard
            option={category}
            key={index}
            onclick={() => setCurrentCategory(category.value)}
            isCurrent={currentCategory === category.value}
          />
        ))}
      </GameSelectContainer>

      <Subtitle subtitle={t('game-options.rounds')} />
      <GameSelectContainer>
        {gameNumberOfRoundOptions.map((option, index) => (
          <GameSelectCard
            option={option}
            key={index}
            onclick={() => setNumberOfRounds(option.value)}
            isCurrent={numberOfRounds === option.value}
          />
        ))}
      </GameSelectContainer>

      <MainButton label={`${loading ? t('lobby.creatingRoom') : t('lobby.createRoom')}`} onclick={() => handleOpenRoom()} />

      </Container>
    </>
  );
};
