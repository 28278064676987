import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { DataProvider } from "./context/useData";
import { SocketProvider } from "./context/useSocket";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "./context/useTheme";
import ReactGA from "react-ga4";

// translation
import "./services/i18n";
import { SoundProvider } from "./context/useSound";

// ReactGA.initialize(process.env.REACT_APP_GA_ID);

// const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <QueryClientProvider client={queryClient}>
      {/* <GoogleOAuthProvider clientId={googleClientId}> */}
        <DataProvider>
          <SocketProvider>
            <ThemeProvider>
              <SoundProvider>
                <App />
              </SoundProvider>
            </ThemeProvider>
          </SocketProvider>
        </DataProvider>
      {/* </GoogleOAuthProvider> */}
    </QueryClientProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
