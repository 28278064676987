import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAvatar, updateUserProfile } from "../../api/userApi";
import { Avatar } from "../../components/avatar/Avatar";
import { MainButton } from "../../components/button/MainButton";
import { Container } from "../../components/container/Container";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import { MainInput } from "../../components/input/MainInput";
import { Loader } from "../../components/loader/Loader";
import { Paragraph } from "../../components/text/Paragraph";
import { Title } from "../../components/text/Title";
import { useData } from "../../context/useData";
import { generateRandomString } from "../../services/dataManipulation";
import { ReactComponent as SwitchIcon } from "../../assets/svg-icons/switch.svg";

const initialProfileForm = {
  avatar: "",
  fullName: "",
  nickName: "",
};

export const Profile = () => {
  const [profileForm, setProfileForm] = useState(initialProfileForm);
  const [errorMessage, setError] = useState("");
  const [forgetMe, setForgetMe] = useState(false);
  const [loadingAvatar, setLoadingAvatar] = useState(false);

  const { user, setUser, handleUserLogout } = useData();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user && user !== 'pending') {
      setProfileForm({
        avatar: user?.avatar,
        fullName: user?.fullName,
        nickName: user?.nickName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { isLoading, isError, refetch, error } = useQuery(
    "update-profile",
    () => updateUserProfile(profileForm, user._id),
    {
      enabled: false,
      onSuccess: ({ data }) => {
        setUser({ ...data.updatedUser });
        navigate("/");
        // if (data.status === 200) {
        //   if (rememberMe) {
        //     handleUserLogin(data.data);
        //     navigate("/");
        //   } else if (!error) {
        //     setUser(data.data.user);
        //     navigate("/");
        //   }
        // }
      },
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  const handleChange = (event) => {
    setError("");
    const { name, value } = event.target;
    setProfileForm({ ...profileForm, [name]: value });
  };

  const handleSubmit = async () => {
 
    setError("");
    let isValid = true;

    // Validation logic

    if (profileForm.fullName.length < 2) {
      setError("Full Name Needs To Be At Least 2 Characters Long");
      return (isValid = false);
    }
    if (profileForm.fullName.length < 2) {
      setError("Nickname Needs To Be At Least 2 Characters Long");
      return (isValid = false);
    }

    if (isValid) {
      await refetch();
    }
  };

  const handleAvatarChange = async () => {
    setLoadingAvatar(true);
    try {
      const randomName = generateRandomString();
      const data = await getAvatar(randomName);
      const url = `data:image/svg+xml,${encodeURIComponent(data)}`;
      setProfileForm((prev) => ({ ...prev, avatar: url }));
    } catch (error) {
      setError("Error With Avatar Selection");
    }
    setLoadingAvatar(false);
  };

  const handleLogout = () => {
    handleUserLogout(forgetMe);
    navigate("/");
  };

  return (
    <Container page>
      <Container>
        <Title title={t("profile.title")} />
        <Paragraph paragraph={t("profile.edit-profile")} />

        {errorMessage && <ErrorMessage text={errorMessage} />}

        {isError && (
          <ErrorMessage
            text={
              error?.response?.data?.message
                ? error?.response?.data?.message
                : t("errors.somthing-went-wrong")
            }
          />
        )}

        {loadingAvatar ? (
          <div className="w-full flex justify-center items-center min-h-[216px]">
            <Loader />
          </div>
        ) : (
          <div className="w-full my-6 flex flex-col items-center">
            <Avatar
              imageUrl={profileForm?.avatar}
              size={"xl"}
              classnames={"border-4"}
            />
            <button
              onClick={handleAvatarChange}
              className="mt-4 text-dark underline flex"
            >
              <SwitchIcon />
              <span className="mx-2">{t("common.change")}</span>
            </button>
          </div>
        )}

        <MainInput
          label={t('profile.full-name')}
          type={"text"}
          name={"fullName"}
          value={profileForm["fullName"]}
          id="fullName"
          placeholder={t('profile.full-name')}
          onchange={handleChange}
          isError={isError}
        />
        <MainInput
          label={t('profile.nickname')}
          type={"text"}
          name={"nickName"}
          value={profileForm["nickName"]}
          id="nickName"
          placeholder={t('profile.nickname')}
          onchange={handleChange}
          isError={isError}
        />

        {isLoading ? (
          <div className="w-full flex justify-center">
            <Loader />
          </div>
        ) : (
          <MainButton label={t('common.update')} onclick={handleSubmit} />
        )}

        <p className="w-full text-center my-4">{t('common.or')}</p>
        <div>
          <div className="flex items-center">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-5 w-5 border-2 border-primary-color rounded-md bg-white checked:bg-primary-color checked:border-white focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left m-0 mr-2 cursor-pointer"
              id="forget-me"
              onClick={() => setForgetMe(!forgetMe)}
            />
            <label
              className={`form-check-label inline-block text-dark mx-2  ${
                forgetMe ? "border-primary-color" : ""
              }`}
            >
             {t('profile.forget-me')}
            </label>
          </div>
          <MainButton label={t('profile.logout')} onclick={() => handleLogout()} />
        </div>
      </Container>
    </Container>
  );
};
