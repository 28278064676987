import React from 'react'

export const ProgressBar = ({percentage, size = 'default'}) => {


  return (
    <div className={` rounded-[10px] overflow-hidden  flex flex-col space-y-3 ${size !== 'defualt' ? size === 'sm' ? 'w-[150px] h-[15px] mx-auto' : '' : ''}`}>
  <div className="relative w-full h-full bg-gray-200 rounded-[10px] ">
    <div style={{width: `${percentage}%`}} className={`absolute top-0 h-full rounded-[10px] shim-progress ${size !== 'default' ? size === 'sm' ? 'py-2' : '' : 'py-4'}  transition-all duration-1000 ease-linear`}></div>
  </div>
  </div>
  )
}
