import React from 'react'
import classNames from 'classnames';

const paragraphClassnames = 'mt-4 text-lg text-gray-600 w-full text-center';


export const Paragraph = ({ paragraph, classnames }) => {
  return (
    <p className={classNames(paragraphClassnames, classnames)}>{paragraph}</p>
  )
}
