import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "../../../container/Container";
import { ProgressBar } from "../../../progress-bar/ProgressBar";
import { Paragraph } from "../../../text/Paragraph";

export const DisplayRiddle = ({ currentRiddle, isSolo, percentage }) => {
  const { t, i18n } = useTranslation();

  return (
    <Container classnames="flex flex-col rounded-3xl min-h-fit">
      <div
        className={`flex ${
          currentRiddle?.subCategory ? "justify-between" : "justify-center"
        } items-end`}
      >
        <Paragraph
          paragraph={`${t(`sub-categories.${currentRiddle?.subCategory?.toLowerCase()}`)}-${t(`categories.${currentRiddle?.category?.toLowerCase()}`)}`}
          classnames={"!mt-0"}
        />
      </div>
      <div className="flex-1 flex items-center justify-center text-center text-[2.6em] tracking-[0.2em] !dir-ltr">
        {currentRiddle?.emojis[i18n.language]}
      </div>
      {!isSolo && <ProgressBar percentage={percentage} />}
    </Container>
  );
};
