import React from "react";
import { ReactComponent as ResetIcon } from "../../../../assets/svg-icons/reset.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/svg-icons/eye.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg-icons/delete.svg";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../context/useTheme";


export const DisplayActionButtons = ({
  ResetChars,
  RevealChar,
  RemoveChar,
  actions,
  loadingAction
}) => {
  const { t } = useTranslation();
  
  const actionButtonClass = `flex flex-col items-center px-1 relative ${loadingAction ? "opacity-60" : ""}`;

  return (
    <div className="flex items-center justify-between my-4 bg-light/70 px-4 py-2 rounded-xl" >
      <button onClick={ResetChars} className={actionButtonClass} disabled={loadingAction} >
        <ResetIcon />
        <span className="text-xs text-grey-color">
          {t("guess-riddle.reset")}
        </span>
      </button>
      <button onClick={RevealChar} className={actionButtonClass} disabled={loadingAction} >
        <ActionLimit limit={actions?.revealChars} />
        <EyeIcon />
        <span className="text-xs text-grey-color">
          {t("guess-riddle.reveal")}
        </span>
      </button>
      <button onClick={RemoveChar} className={actionButtonClass} disabled={loadingAction} >
        <ActionLimit limit={actions?.removeChars} />
        <DeleteIcon />
        <span className="text-xs text-grey-color">
          {t("guess-riddle.remove")}
        </span>
      </button>
    </div>
  );
};

const ActionLimit = ({ limit }) => {
  const { isRtl } = useTheme();
  return (
    <span
      style={isRtl ? {left: "100%"} : {right: "100%"}}
      className={`text-sm font-bold text-secondery-color absolute`}
    >
      {limit}
    </span>
  );
};
