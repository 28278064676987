import { useEffect, useState } from "react";

export const useInterval = (initialTime) => {
    const [timer, setTimer] = useState(initialTime);
    const [isFinnished, setIsFinnished] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    
    useEffect(() => {
      let interval;
      if (isRunning && !isFinnished) {
        interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      }
    
      return () => {
        clearInterval(interval);
      };
    }, [isRunning, isFinnished]);
    
    useEffect(() => {
      if (timer === 0) {
        setIsFinnished(true);
      }
    }, [timer]);
    
    const getPercentage = () => {
      return 100 - (timer * 100) / initialTime;
    };

    const restartTimer = () => {
      setTimer(initialTime);
      setIsFinnished(false);
  };
    
    return { timer, percentage: getPercentage(), setIsRunning, restartTimer };
}
