/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import classNames from "classnames";
import { Header } from "../header/Header";
import { Popup } from "../popup/Popup";
import { useData } from "../../context/useData";
// import { useWindowSize } from "../../hooks/useWIndowSize";


export const Container = ({ children, classnames, page, noHeader }) => {
  // const [width, height] = useWindowSize();
  const { handlePopup, setHandlePopup } = useData();

  const containerStyle =
    "w-[100%] max-w-2xl mb-6 p-4 backdrop-blur-sm bg-white/50 p-4 rounded-2xl shadow-md  min-h-[150px]";
  const containerPageStyle = `w-[100%] h-[100%] ${noHeader ? 'p-4' : ''} flex flex-col flex-1 `;

  let containerMobileStyle = {};

  const RenderPopup = useCallback(() => {
    if (handlePopup.showPopup && page)
      return (
        <Popup
          data={{
            ...handlePopup?.popupData,
            close: () => {
              handlePopup?.popupData?.onclose();
              setHandlePopup({});
            },
          }}
        />
      );
  }, [handlePopup.showPopup]);

  return (
    <>
      <RenderPopup />
      <div
        style={containerMobileStyle}
        className={classNames(
          page ? containerPageStyle : containerStyle,
          classnames
        )}
      >
        {page && !noHeader && <Header />}
        {page ? <div className={`w-[100%] h-[100%] max-w-[550px] mx-auto ${page ? 'overflow-y-auto' : ''} ${noHeader ? '' : 'p-4'}`}>
        {children}
        </div> : <>{children}</>}
      </div>
    </>
  );
};
