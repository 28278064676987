import { Avatar } from "../../avatar/Avatar";

export const UserCard = ({ user, index }) => {


  const isCurrent = index === 0;
  

  return (
    <div className="flex flex-col items-center mx-4">

      <Avatar imageUrl={user.avatar} size={"md"} classnames={`border-4 ${isCurrent ? 'border-primary-color' : 'border-stone-400'}`} />
      <p
        className={`text-4 ${
          user.isFinnishedRound ? "text-green-400" : "text-neutral-900"
        } `}
      >
        {user?.name?.split(" ")[0]}
      </p>
    </div>
  );
};
