import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { handleLoginGoogleUser, loginUser } from "../../../api/userApi";
import { useData } from "../../../context/useData";
// import { useGoogleLogin } from "@react-oauth/google";
import { Container } from "../../../components/container/Container";
import { MainButton } from "../../../components/button/MainButton";
import { MainInput } from "../../../components/input/MainInput";
import { ErrorMessage } from "../../../components/errors/ErrorMessage";
import { Loader } from "../../../components/loader/Loader";
import { useTranslation } from "react-i18next";
import { urls } from "../../../globalConsts";

const initialLoginForm = {
  email: "",
  password: "",
};

export const Login = () => {
  const [loginForm, setloginForm] = useState(initialLoginForm);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setError] = useState("");
  const [loadingGoogleLogin, setLoadingGoogleLogin] = useState(false);

  const { t } = useTranslation();
  const { setUser, user, handleUserLogin } = useData();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(user)
    if (user && user !== 'pending') navigate("/");
  }, [])
  

  const { isLoading, isError, refetch, error } = useQuery(
    "login",
    () => loginUser(loginForm),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.status === 200) {
          if (rememberMe) {
            handleUserLogin(data.data);
            navigate("/");
          } else if (!error) {
            setUser(data.data.user);
            navigate("/");
          }
        }
      },
    }
  );

  // const googleLogin = useGoogleLogin({
  //   flow: "implicit",
  //   scope: "profile",
  //   onSuccess: async (tokenResponse) => {
  //     setLoadingGoogleLogin(true);
  //     try {
  //       const { access_token } = tokenResponse;
  //       sessionStorage.setItem("google_access_token", access_token);
  //       const googleUser = await handleLoginGoogleUser(access_token);

  //       setUser({ ...googleUser, isGoogleUser: true });
  //       navigate("/");
  //     } catch (err) {
  //       console.log(err);
  //       setError(err.message);
  //     }
  //     setLoadingGoogleLogin(false);
  //   },
  //   onError: (error) => setError("Somthing went wrong"),
  //   onNonOAuthError: (nonAuthError) => setError("Somthing went wrong"),
  // });

  const handleChange = (event) => {
    setError("");
    const { name, value } = event.target;
    setloginForm({ ...loginForm, [name]: value });
  };

  const handleSubmit = async () => {
  
    setError("");
    let isValid = true;

    // Validation logic
    if (!loginForm.email || !loginForm.password) {
      setError(t("errors.login.fields"));
      return (isValid = false);
    }
    if (loginForm.email) {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      if (!emailRegex.test(loginForm.email)) {
        setError(t("errors.login.valid-email"));
        return (isValid = false);
      }
    }
    if (loginForm.password) {
      if (loginForm.password.length < 6) {
        setError(t("errors.login.short-password"));
        return (isValid = false);
      }
    }

    if (isValid) {
      await refetch();
    }
  };


  return (
    <Container page>
      <Container>
        <div className={" w-[100%]"}>
          <div className="flex flex-col items-center lg:justify-start">
            <p className="text-2xl text-dark mb-0 mr-4">
              {t("login.sign-in")}
            </p>

          </div>

          {errorMessage && <ErrorMessage text={errorMessage} />}
          {isError && (
            <ErrorMessage
              text={
                error?.response?.data?.message
                  ? error?.response?.data?.message
                  : t("errors.somthing-went-wrong")
              }
            />
          )}

          <MainInput
            label={t("common.email")}
            type={"email"}
            name={"email"}
            value={loginForm["email"]}
            id="email"
            placeholder={t("login.email-address")}
            onchange={handleChange}
            isError={isError}
          />

          <MainInput
            label={t("common.password")}
            type={"password"}
            name={"password"}
            value={loginForm["password"]}
            id="password"
            placeholder={t("common.password")}
            onchange={handleChange}
            isError={isError}
          />

          <div className="flex justify-between items-center mb-6">
            <div className="form-group form-check flex items-center">
              <input
                type="checkbox"
                className="form-check-input appearance-none h-5 w-5 border-2 border-primary-color rounded-md bg-white checked:bg-primary-color checked:border-white focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                id="exampleCheck2"
                onClick={() => setRememberMe(!rememberMe)}
              />
              <label
                className={`form-check-label inline-block text-dark mx-2 ${
                  rememberMe ? "border-primary-color" : ""
                }`}
              >
                {t("login.remember-me")}
              </label>
            </div>
            <Link to={urls.forgotPassword} className="text-dark underline">
              {t("login.forgot-password")}?
            </Link>
          </div>

          <div className="text-center lg:text-left">
            {isLoading ? (
              <Loader />
            ) : (
              <MainButton
                label={t("common.login")}
                onclick={handleSubmit}
              />
            )}

            <div className="flex items-center mt-6 pb-2">
              <p className="text-md  text-dark  mb-0">
                {t("login.dont-have-account")}?
              </p>
              <Link
                to={urls.register}
                className="text-secondery-color underline hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out mx-2"
              >
                {t("common.register")}
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};
