import classNames from 'classnames';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import {ReactComponent as ChevronLeft} from '../../assets/svg-icons/chevron-left.svg';
import {ReactComponent as ChevronRight} from '../../assets/svg-icons/chevron-right.svg';
import useSound from 'use-sound';
import buttonSound from '../../assets/sounds/main-click.mp3'
import { useAppSound } from '../../context/useSound';


export const LeaveButton = ({ toUrl, classnames, onclick }) => {
    const navigate = useNavigate();
  const { t } = useTranslation();
    const leaveButtonClassnames = `w-fit mb-2 flex items-center bg-light rounded-lg px-2`;

    const {   
      volume,
      isSfx
    } = useAppSound()

    const [play, { sound }] = useSound(buttonSound, {
      playbackRate: 0.75,
      interrupt: true,
    });
  
    useEffect(() => {
      if (sound) {
        sound.volume(isSfx ? volume : 0);
      }
    }, [volume, isSfx, sound]);


  return (
    <button onClick={async() => {
      if (isSfx) play()
      if (onclick) await onclick()
      navigate(toUrl)
    }} className={classNames(leaveButtonClassnames,classnames)}>
      {/* <ChevronRight width={14} height={14} /> */}
      {/* <ChevronLeft />  */}
      {t('common.go-back')}
    </button>
  )
}
