import React, { useEffect, useRef, useState } from "react";
import gameMusic from "../../assets/sounds/game-music.mp3";
import { useAppSound } from "../../context/useSound";
import useSound from "use-sound";

export const BackgroundMusic = ({}) => {
  const { volume, isMusic } = useAppSound();

  const [play, { sound, stop }] = useSound(gameMusic, {
    playbackRate: 1.4,
    interrupt: true,
    loop: true, 
    autoplay: false,
  });

  useEffect(() => {
    if (isMusic) {
      play();
    } else {
      stop();
    }
  }, [isMusic, play, stop]);

  useEffect(() => {
    if (sound) {
      sound.volume(volume);
    }
  }, [volume, sound]);

};
