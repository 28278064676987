/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchUser, fetchUserByToken, handleLoginGoogleUser } from "../api/userApi";

export const DataContext = React.createContext({});

export const DataProvider = ({ children }) => {
  const [user, setUser] = useState('pending');
  const [loadingUser, setLoadingUser] = useState(true);
  const [, setCurrentToken] = useState("");
  const [handlePopup, setHandlePopup] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  // const [levels, setLevels] = useState([]);
 
// Handle user token
  useEffect(() => {
        // Get token from storage
        const token = sessionStorage.getItem("token");
        const googleToken = sessionStorage.getItem("google_access_token");
        
        setLoadingUser(true);

        if (token) {
          // Check if can get user by token
          try {
            (async () => {
              const { data } = await fetchUserByToken(token);
              setUser(data);
            })();
          } catch (error) {
            localStorage.removeItem("token");
            setUser(null)
          }
        } else if (googleToken) {
          // Check if can get google user by token
          (async () => {
            try {
              const googleUser = await handleLoginGoogleUser(googleToken);
              if (googleUser) setUser({ ...googleUser, isGoogleUser: true });
            } catch (error) {
              sessionStorage.removeItem("google_access_token");
              setUser(null)
            }
          })();
        } else setUser(null)
        setLoadingUser(false);
  }, []);

// Handle saved styles
  useEffect(() => {

    // Font Size
    const fontSize = localStorage.getItem('font-size')
    if (fontSize) document.documentElement.style.setProperty("font-size", `${fontSize}px`);

  }, [])

  const handleUserLogin = ({ user, token }) => {
    sessionStorage.setItem("token", token);
    setUser(user);
  };

  const handleUserLogout = (isForget) => {
    if (isForget) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("google_access_token")
    }
    setUser(null);
  };

  const refetchUser = async () => {
    const token = sessionStorage.getItem("token");
    setLoadingUser(true);

    if (token) {
      const { data } = await fetchUserByToken(token);
      setUser(data);
      setCurrentToken(token);
    } else {
      const data = await fetchUser(user?._id);
      setUser((prev) => ({ ...prev, ...data }));
    }

    setLoadingUser(false);
  };

  const getLevels = async (type) => {

  }

  const contextValue = {
    user,
    setUser,
    handleUserLogin,
    loadingUser,
    refetchUser,
    handleUserLogout,
    handlePopup,
    setHandlePopup,
    pageLoading,
    setPageLoading,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
