import classNames from 'classnames'
import React from 'react'

export const GameSelectContainer = ({ children ,classnames }) => {
    const gameSelectCOntainerClassnames = `flex-1 w-full my-4 grid grid-cols-2 gap-4`
  return (
    <div className={classNames(gameSelectCOntainerClassnames, classnames)}>
        {children}
    </div>
  )
}
