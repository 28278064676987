import React from 'react'
import classNames from 'classnames';

const subtitleClassnames = 'text-2xl font-bold text-gray-800 leading-tight block text-center  '

export const Subtitle = ({ subtitle, classnames }) => {
  return (
    <h1 className={classNames(subtitleClassnames, classnames)}>{subtitle}</h1>
  )
}
