import React, { useEffect } from "react";
import { useAppSound } from "../../../../context/useSound";
import useSound from "use-sound";
import buttonSound from "../../../../assets/sounds/secondery-click.mp3";


export const DisplayAnswer = ({ ans, handleRemoveChar, endRoundTimer, setNextLocation, nextLocation }) => {

  const { volume, isSfx } = useAppSound();

  const [play, { sound }] = useSound(buttonSound, {
    playbackRate: 0.75,
    interrupt: true,
  });

  useEffect(() => {
    if (sound) {
      sound.volume(isSfx ? volume : 0);
    }
  }, [volume, isSfx, sound]);

  return (
    <div className="flex flex-1 flex-col items-center my-4">
      {ans?.map((word, wordIndex) => {
        return (
          <div
            className="flex items-center justify-center flex-wrap h-fit mb-6"
            key={wordIndex}
          >
            {word.map((char, charIndex) => (
              <h4
                className={`text-2xl mr-4 w-8 h-8 mb-4 flex items-center justify-center  rounded-lg shadow-md border-2 border-primary-color bg-white/30  ${
                  char.isRevealed
                    ? " border-secondery-color bg-white"
                    : ((nextLocation[0] === wordIndex && nextLocation[1] === charIndex) ? "!bg-primary-color/70" : "backdrop-blur-sm ")
                }
                ${
                    char.currentValue
                       ? (endRoundTimer ? "!bg-primary-color !border-0" : "!bg-white")
                     : "text-transparent"
                  }
                  `}
                key={charIndex}
                onClick={() => {
                  if (isSfx) play()
                  if (!char.isRevealed) {
                    if (char.currentValue) {
                      return handleRemoveChar(char);
                    } else {
                      setNextLocation([wordIndex, charIndex])
                    }
                  }
                }}
              >
                {/* {char?.currentValue ? char?.currentValue?.toUpperCase() : '_' } */}
                {char.isRevealed ? char.realValue?.toUpperCase() : char?.currentValue?.toUpperCase()}
              </h4>
            ))}
          </div>
        );
      })}
    </div>
  );
};
