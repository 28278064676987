import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getGameTypeLevels } from "../../api/gameApi";
import { LeaveButton } from "../../components/button/LeaveButton";
import { MainButton } from "../../components/button/MainButton";
import { Container } from "../../components/container/Container";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import { ProgressBar } from "../../components/progress-bar/ProgressBar";
import { Paragraph } from "../../components/text/Paragraph";
import { Title } from "../../components/text/Title";
import { useData } from "../../context/useData";
import { urls } from "../../globalConsts";
import { Loading } from "../loading/Loading";
import data from "../../constants/data/levels-full.json";
import buttonSound from "../../assets/sounds/main-click.mp3";
import { useAppSound } from "../../context/useSound";
import useSound from "use-sound";

export const LevelChoose = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const { user } = useData();
  const [levelsLinks, setLevelsLinks] = useState([]);

  // const { isLoading, data, isError, error } = useQuery(
  //   ["get-levels-amount", user, type],
  //   () => getGameTypeLevels(type, user._id),
  //   {}
  // );

  const levels = data["levels"].filter(
    (currentLevel) => currentLevel.category === type
  );

  // user stages completed ids array
  const levelsCompletedIds = user?.game?.solo?.levelsCompletedIds;

  useEffect(() => {
    if (user && user !== "pending") {
      const levelsAmount = levels.length / 10;
      const tempLevelsLinks = [];

      for (let i = 0; i < levelsAmount; i++) {
        const currentLevelStagesIds = levels
          .filter((level) => level.level == i + 1)
          .map((level) => level._id);

        // check if includes
        const allIdsIncluded = currentLevelStagesIds.every((id) =>
          levelsCompletedIds.includes(id)
        );

        // calculate progress
        const completedStages = currentLevelStagesIds.filter((id) =>
          levelsCompletedIds.includes(id)
        ).length;

        const progress =
          currentLevelStagesIds.length > 0
            ? (completedStages / currentLevelStagesIds.length) * 100
            : 0;

        tempLevelsLinks[i] = {
          value: i + 1,
          isCompleted: allIdsIncluded,
          progress,
        };
      }
      setLevelsLinks(tempLevelsLinks);
    }
  }, [user]);

  const { volume, isSfx } = useAppSound();

  const [play, { sound }] = useSound(buttonSound, {
    playbackRate: 0.75,
    interrupt: true,
  });

  useEffect(() => {
    if (sound) {
      sound.volume(isSfx ? volume : 0);
    }
  }, [volume, isSfx, sound]);

  // if (isLoading) return <Loading />;

  return (
    <Container page>
      <LeaveButton toUrl={`/solo-choose`} />

      <Container>
        <Title title={t("level-choose.choose-level")} />
        <Paragraph paragraph={t("level-choose.choose-level-play")} />

        {/* {isError && (
          <ErrorMessage
            text={
              error?.response?.data?.message
                ? error?.response?.data?.message
                : t("errors.somthing-went-wrong")
            }
          />
        )} */}

        <div className="w-full my-2 flex items-center justify-center">
          <h4>{t(`solo-choose.${type}`)}</h4>
        </div>
      </Container>

      <div className="mt-4 flex-1 flex flex-col">
        {levelsLinks.length > 0 &&
          levelsLinks.map((level, index) => (
            <Link
              onClick={() => {
                if (isSfx) play()
              }}
              key={index}
              to={`/solo/${type}/${level.value}`}
              className={`flex flex-col items-center justify-center w-full text-center p-4 border-4 border-primary-color  backdrop-blur-md rounded-lg mb-4 ${
                level.isCompleted
                  ? "bg-light/80 backdrop-brightness-0"
                  : "bg-light/80"
              }`}
            >
              <span className="mb-2">
                {t("level-choose.level")} {level.value}
              </span>{" "}
              <ProgressBar percentage={level.progress} size={"sm"} />
            </Link>
          ))}
      </div>
    </Container>
  );
};
