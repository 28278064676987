import shortid from "shortid";

// takes chars array shuffle and adds a random element to the array
export const shuffleAndAddRandom = (chars, language) => {
  let charPool = "";
  switch (language) {
    case "en":
      charPool = "abcdefghijklmnopqrstuvwxyz";
      break;
    case "he":
      charPool = "ףךץםאבגדהוזחטיכלמנסעפצקרשת";
      break;
    case "ru":
      charPool = "абвгдеёжзийклмнопрстуфхцчшщъыьэюя";
      break;
    case "es":
      charPool = "abcdefghijklmnñopqrstuvwxyz"; // Spanish alphabet includes "ñ"
      break;
    // Add more cases for other languages as needed
    default: // Default to English alphabet
      charPool = "abcdefghijklmnopqrstuvwxyz";
      break;
  }

  // Create a new array with the original characters and 5 new random ones
  const newChars = [
    ...chars,
    ...Array.from({ length: 5 }, () => {
      const randomChar = charPool[Math.floor(Math.random() * charPool.length)];
      return createGuessCharObject(randomChar, false, false);
    }),
  ];

  // Shuffle the new array using the Fisher-Yates shuffle algorithm
  for (let i = newChars.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newChars[i], newChars[j]] = [newChars[j], newChars[i]];
  }

  return newChars;
};

export const createAnswerCharObject = (char, isRevealed) => {
  const id = shortid.generate();
  return {
    id,
    realValue: char,
    isRevealed,
    currentValue: isRevealed ? char : null,
    selectedCharId: "",
  };
};

export const createGuessCharObject = (char, isHidden, isAns) => {
  const id = shortid.generate();
  return {
    id,
    value: char,
    isHidden,
    isAns,
    isRemoved: false,
  };
};

export const hideCharInGuessArray = (guessArray, char) => {
  return guessArray.map((currChar, index) => {
    if (currChar.id === char.id) {
      return { ...currChar, isHidden: true };
    } else return currChar;
  });
};

export const showCharInGuessArray = (guessArray, char) => {
  return guessArray.map((currChar, index) => {
    if (currChar.id === char.id) {
      return { ...currChar, isHidden: false };
    } else return currChar;
  });
};

export const addCharToPlace = (char, answer, location) => {
  const newAnswer = [...answer];

  // cset char in chosen place
  if (location.length > 0) {
    
    newAnswer[location[0]][location[1]] = {
      ...newAnswer[location[0]][location[1]],
      currentValue: char.value,
      selectedCharId: char.id
    }

  } else {
    // set in first availble space

    let modified = false;
    newAnswer.forEach((innerArr, i) => {
      innerArr.some((currChar, j) => {
        if (currChar.currentValue === null && !modified) {
          currChar.currentValue = char.value;
          currChar.selectedCharId = char.id;
          modified = true;
          return true;
        }
        return false;
      });
    });
  }

  return newAnswer;
};

export const removeCharFromAnswer = (answer, char) => {
  let newAnswer, originalCharId;

  newAnswer = answer.map((word) => {
    return word.map((answerChar) => {
      if (answerChar.id === char.id) {
        // clear currentValue and selectedCharId
        answerChar.currentValue = null;
        originalCharId = answerChar.selectedCharId;
        answerChar.selectedCharId = "";
        return answerChar;
      } else return answerChar;
    });
  });

  return { newAnswer, originalCharId };
};

export const findGuessCharByIdAndReveal = (guessArray, charIds) => {
  const newGuessArray = guessArray.map((currChar, index) => {
    if (charIds.includes(currChar.id)) {
      return { ...currChar, isHidden: false };
    } else return currChar;
  });
  return newGuessArray;
};

// function to check if there are any hidden characters in the answer
export const hasHiddenChar = (hiddenAnswer) => {
  let hasHiddenChar = false;
  hiddenAnswer.forEach((word) => {
    word.forEach((char) => {
      if (char.currentValue === null) {
        hasHiddenChar = true;
      }
    });
  });
  return hasHiddenChar;
};

export const CheckIfAnswerIsCorrect = (answer) => {
  let isCorrect = true;

  answer.forEach((word) => {
    word.forEach((char) => {
      if (char.currentValue !== char.realValue) isCorrect = false;
    });
  });

  return isCorrect;
};

export const removeNonIsRevealedChars = (answer) => {
  let originalCharsIds = [];

  const newAnswer = answer.map((word) => {
    return word.map((answerChar) => {
      if (!answerChar.isRevealed && answerChar.currentValue !== null) {
        originalCharsIds.push(answerChar.selectedCharId);
        return { ...answerChar, currentValue: null, selectedCharId: "" };
      } else return answerChar;
    });
  });

  return { newAnswer, originalCharsIds };
};

export const getRandomItemFromArray = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};
