import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import buttonSound from "../../assets/sounds/main-click.mp3";
import useSound from "use-sound";
import { useAppSound } from "../../context/useSound";

// const mainButtonClassnames = "relative overflow-hidden border-4 border-white rounded-[22px]  w-[100%] h-[85px] flex items-center justify-center bg-[#FDC913] text-2xl text-white  shadow-[inset_0_0_7px_6px_rgba(254,148,49,0.9)] mt-6";

export const MainButton = ({
  label,
  link,
  onclick,
  width = "64px",
  disabled,
  classnames = "",
}) => {
  const mainButtonClassnames = `relative overflow-hidden border-4 border-white rounded-[22px]  w-[100%] h-[70px] flex items-center justify-center bg-primary-color text-3xl text-white  inner-outer-shadow mt-4 uppercase ${
    disabled ? "opacity-60" : ""
  } ${disabled ? "pointer-events-none" : ""}`;

  const { volume, isSfx } = useAppSound();

  const [play, { sound }] = useSound(buttonSound, {
    playbackRate: 0.75,
    interrupt: true,
  });

  useEffect(() => {
    if (sound) {
      sound.volume(isSfx ? volume : 0);
    }
  }, [volume, isSfx, sound]);

  const handleButtonClick = () => {
   if (isSfx) play();
    // Call the onClick function, if provided
    if (onclick) {
      onclick();
    }
  };

  return (
    <>
      {link ? (
        <Link
          to={link}
          className={mainButtonClassnames + " " + classnames}
          disabled={disabled}
          onClick={handleButtonClick}
        >
          {/* <div className="bg-[#ffffff] w-[30px] h-[30px] absolute top-1 left-4 egg-shape shadow-lg rotate-45 "/> */}
          <h2 className="z-10">{label}</h2>
          <div className="bg-[#FFE68E] w-[100%] h-[100%] absolute z-0 opacity-30   clip-triangle" />
          {/* <div className="bg-[#D2A70D] w-[100%] h-[100%] absolute z-0   clip-circle" /> */}
        </Link>
      ) : (
        <button
          onClick={handleButtonClick}
          className={mainButtonClassnames + " " + classnames}
          disabled={disabled}
        >
          {/* <div className="bg-[#f4ffad] w-[100%] h-[100%] absolute   clip-your-needful-style"/>
            <div className="bg-[#ffffff] w-[30px] h-[30px] absolute top-1 left-4 egg-shape shadow-lg rotate-45 "/> */}
          <h2 className="z-10">{label}</h2>
          <div className="bg-[#FFE68E] w-[100%] h-[100%] absolute z-0 opacity-30   clip-triangle" />
        </button>
      )}

      {/* <audio ref={audioRef} src={buttonSound} /> */}
    </>
  );
};
