import React from "react";
import { MainButton } from "../../button/MainButton";
import { Container } from "../../container/Container";
import { Paragraph } from "../../text/Paragraph";
import { Title } from "../../text/Title";
import { useTranslation } from "react-i18next";

export const WaitForAllPlayers = ({ setStep, socketUsers, isFirstRound }) => {
  const { t } = useTranslation();
  const handleNextStep = () => {
    setStep(1);
  };
  return (
    <div className="flex flex-col items-center mt-4 ">
      <Container>
        {isFirstRound ? (
          <>
            <Title title={t('gameRoom.!isFinnishedGame.!isFinnishedRound.CurrentPlayer.WaitForAllPlayers.isFirstRound.title')} />

            <Paragraph paragraph={t('gameRoom.!isFinnishedGame.!isFinnishedRound.CurrentPlayer.WaitForAllPlayers.isFirstRound.body')} />
            <Paragraph paragraph={`${t('common.players')}: ${socketUsers.length}`} />
          </>
        ) : (
          <>
            <Title title={t('gameRoom.!isFinnishedGame.!isFinnishedRound.CurrentPlayer.WaitForAllPlayers.!isFirstRound.title')} />

            <Paragraph paragraph={t('gameRoom.!isFinnishedGame.!isFinnishedRound.CurrentPlayer.WaitForAllPlayers.!isFirstRound.body')} />
          </>
        )}
        <MainButton
          disabled={socketUsers.length < 2}
          onclick={handleNextStep}
          label={t('game.start')}
        />
      </Container>
    </div>
  );
};
