import React from 'react'
import { MainButton } from '../../components/button/MainButton'
import { Title } from '../../components/text/Title'

// Assets
import {ReactComponent as SoloIcon} from '../../assets/svg-icons/solo.svg';
import {ReactComponent as GroupIcon} from '../../assets/svg-icons/group.svg';
// import {ReactComponent as AiIcon} from '../../assets/svg-icons/ai.svg';
import { useData } from '../../context/useData';
import { Container } from '../../components/container/Container';
import { Avatar } from '../../components/avatar/Avatar';
import { useTranslation } from 'react-i18next';
import { urls } from '../../globalConsts';

const iconClassnames = 'fill-light w-[36px] h-[36px] mx-2'

export const GameChoose = () => {
    const { user } = useData();
    const { t } = useTranslation();
    
  return (
    <Container page>
      <Container>
        <Title title={t('choose-game.choose-game-type')} classnames={''} />
        <div className='flex flex-col items-center mt-6'>
          <Avatar imageUrl={user?.avatar} size={'lg'} />
          <p className='mt-2'>{user?.nickName ? user?.nickName : t('common.guest')}</p>
        </div>
        <MainButton disabled={!user} link={urls.soloChoose} label={<div className='flex items-center'><SoloIcon className={iconClassnames} /> {t('choose-game.solo')}</div>} />
        <MainButton disabled={true} link={user ? urls.lobby : urls.enterUser} label={<div className='flex items-center'><GroupIcon className={iconClassnames} /> {t('choose-game.group')}</div>} />
        {/* <MainButton disabled={!user} link={'/ai'} label={<div className='flex items-center'><AiIcon className={iconClassnames} />Ai</div>} /> */}
      </Container>
    </Container>
  )
}
