import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFontFamily } from "../services/functions";

export const ThemeContext = React.createContext({});

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const { i18n } = useTranslation();


  // Handle fontFamily by language change
  useEffect(() => {
    // keep only the 2 first characters
    const lang = i18n?.language?.length > 2 ? i18n?.language.slice(0,2) : i18n?.language;
    const fontFamily = getFontFamily(lang);
    // set the font family
    document.body.style.fontFamily = fontFamily;
  }, [i18n.language]);

  const contextValue = {
    isDarkMode,
    setIsDarkMode,
    isRtl,
    setIsRtl
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
