import React, { useEffect } from "react";
import { SelectInput } from "../select-input/SelectInput";
import { languageOptions } from "../../globalConsts";
import { useTranslation } from "react-i18next";

export const LanguageSelect = () => {
  const { t, i18n } = useTranslation();
  

  const langOptions = languageOptions.map((option) => ({
    ...option,
    label: t(`languages.${option.value}`),
    key: option.value,
  }));

  const selectedLang = langOptions.find(
    (lang) => lang.value === i18n?.language.slice(0, 2).toLocaleLowerCase()
  );

  useEffect(() => {
    if (selectedLang && selectedLang.value !== i18n.language) {
      i18n.changeLanguage(selectedLang.value);
    }
  }, [i18n, selectedLang]);

  const renderOption = (option) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={option.icon} alt={option.label} style={{ marginRight: 8 }} />
      <span>{t(`languages.${option.label}`)}</span>
    </div>
  );

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
  };

  return (
    <>
      {selectedLang && (
        <SelectInput
          key={selectedLang.value}
          initialValue={selectedLang}
          options={langOptions}
          classnames={"mt-2"}
          onchange={handleLanguageChange}
          renderOption={renderOption}
          isSearchable={false}
        />
      )}
    </>
  );
};
