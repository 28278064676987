/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useData } from "../../../../context/useData";
import { roundTimeInSeconds } from "../../../../globalConsts";
import { useInterval } from "../../../../hooks/useInterval";
import { useRiddle } from "../../../../hooks/useRiddle";
import { Loading } from "../../../../pages/loading/Loading";
import { LeaveButton } from "../../../button/LeaveButton";
import { DisplayActionButtons } from "./DisplayActionButtons";
import { DisplayAnswer } from "./DisplayAnswer";
import { DisplayChars } from "./DisplayChars";
import { DisplayRiddle } from "./DisplayRiddle";
import { useAppSound } from "../../../../context/useSound";
import useSound from "use-sound";
import finnishLevelSound from "../../../../assets/sounds/finnish-level.wav";

const GuessRiddle = ({ currentRiddle, isSolo, setStep, finnishRound, leaveUrl, actions }) => {
  
    const { i18n } = useTranslation();  

    // Timer
    const { timer, percentage, setIsRunning } = useInterval(roundTimeInSeconds);
    

 

  const {
    loadingGame,
    hiddenAnswer, // Array of arrays of objects (array of words containing char objects ) which represent the characters displayed in answer
    charsToGuess, // Array the char objects represnting the characters to be guessed from the current riddle
    handleRemoveCharFromAnswer,
    handleMoveCharToAnswer,
    resetGame,
    removeWrongCharFromCharsToGuess,
    revealCorrectRandomCharInAnswer,
    loadingAction,
    endRoundTimer,
    setNextLocation,
    nextLocation,
  } = useRiddle(currentRiddle, setStep, finnishRound, timer, i18n.language, isSolo);

  useEffect(() => {
    if (hiddenAnswer && hiddenAnswer.length > 0 && !isSolo) setIsRunning(true)
  }, [hiddenAnswer]);

  const { volume, isSfx } = useAppSound();

  const [play, { sound }] = useSound(finnishLevelSound, {
    playbackRate: 0.75,
    interrupt: true,
  });

  useEffect(() => {
    if (sound) {
      sound.volume(isSfx ? volume : 0);
    }
  }, [volume, isSfx, sound]);


  useEffect(() => {
    if (endRoundTimer && isSfx) play()
  }, [endRoundTimer])
  

  if (loadingGame || hiddenAnswer.length < 1 || charsToGuess.length < 1) return <Loading />;

  return (
    <div className="flex flex-col flex-1 justify-between h-full">
      {/* <LeaveButton toUrl={leaveUrl}/> */}
      <DisplayRiddle
        currentRiddle={currentRiddle}
        isSolo={isSolo}
        percentage={percentage}
      />
      <DisplayAnswer
        ans={hiddenAnswer}
        handleRemoveChar={handleRemoveCharFromAnswer}
        endRoundTimer={endRoundTimer}
        setNextLocation={setNextLocation}
        nextLocation={nextLocation}
      />
      <DisplayActionButtons
        ResetChars={resetGame}
        RevealChar={revealCorrectRandomCharInAnswer}
        RemoveChar={removeWrongCharFromCharsToGuess}
        actions={actions}
        loadingAction={loadingAction}
      />
      <DisplayChars chars={charsToGuess} moveChar={handleMoveCharToAnswer} />
    </div>
  );
};

export default GuessRiddle;
